import React, { FC } from 'react';
import { HStack, Image, Text, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { DateFormatter } from '@app/components/DateFormatter';
import { PriceField } from '@app/components/PriceField';
import { useOrderQuery } from '@app/api/schemas/order.amazon.generated';

import { SkeletonItem } from './Skeleton';

interface OrderRefProps {
  id: string;
  isWidget?: boolean;
}

const imageServer = process.env.REACT_APP_AMAZON_IMAGE_SERVER;

export const OrderRef: FC<OrderRefProps> = ({ id, isWidget = false }) => {
  const { t } = useTranslation('tables');
  const navigate = useNavigate();

  const { data: { order: item } = {}, loading } = useOrderQuery({
    variables: {
      id,
    },
    context: {
      clientName: 'amazon',
    },
  });

  const price = item?.offer?.price;

  if (loading) {
    return <SkeletonItem />;
  }

  if (!item) {
    return null;
  }

  return (
    <HStack
      spacing={isWidget ? 2 : 3}
      onClick={() => navigate(`/orders/${item?._id}`)}>
      <Image
        borderRadius={8}
        height={isWidget ? '30px' : '50px'}
        objectFit="cover"
        src={`${imageServer}/${item?.offer?.photo?.[0]?.key}?width=100&format=webp`}
        width={isWidget ? '30px' : '50px'}
      />
      <VStack alignItems="flex-start" spacing={0}>
        <Text color="grey.300" fontWeight={600} noOfLines={1}>
          {item?.offer?.title}
        </Text>
        {!!item?.startAt && !!item?.endAt && (
          <Text color="grey.300" fontSize={12} noOfLines={1}>
            <DateFormatter
              date={new Date(item?.startAt)}
              format={isWidget ? 'P' : 'PP'}
            />{' '}
            -{' '}
            <DateFormatter
              date={new Date(item?.endAt)}
              format={isWidget ? 'P' : 'PP'}
            />
          </Text>
        )}
      </VStack>

      <VStack alignItems="flex-start" spacing={0}>
        <Text
          color="grey.250"
          fontSize={12}
          fontWeight={600}
          whiteSpace="nowrap">
          {t('price')}
        </Text>
        <Text color="grey.300" fontWeight={500}>
          <PriceField value={price} />
        </Text>
      </VStack>
    </HStack>
  );
};
