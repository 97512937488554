import { Button } from '@chakra-ui/react';
import React, { useCallback } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';

const options: DropzoneOptions = {
  accept: {
    'image/jpeg': [],
    'image/jpg': [],
    'image/png': [],
  },
  maxSize: 1024 * 1024 * 5,
};

export const FileDropzone = (props: {
  content: JSX.Element | string;
  dropzoneOptions: DropzoneOptions;
}) => {
  const { content, dropzoneOptions, ...rest } = props;
  const { open, getInputProps } = useDropzone({
    ...options,
    ...dropzoneOptions,
  });

  const handleOpen = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    open();
  }, []);

  return (
    <Button onClick={handleOpen} {...rest}>
      {content}
      <input {...getInputProps()} />
    </Button>
  );
};
