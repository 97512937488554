import { ChakraProvider } from '@chakra-ui/react';
import 'assets/css/App.css';
import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import reportWebVitals from './reportWebVitals';
import theme from './theme';

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ChakraProvider portalZIndex={400} theme={theme}>
      <App />
    </ChakraProvider>
  </React.StrictMode>,
);

reportWebVitals();
