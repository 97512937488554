import * as Types from '@/gql/__generated__/types';

import { gql } from '@apollo/client';
import { CurrencyFragmentDoc } from './currency.mongo.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RequestPriceFragment = { __typename?: 'RequestPrice', value: number, currency: { __typename?: 'Currency', _id: any, code: string | null, name: string | null, symbol: string | null } | null };

export type MessageFragment = { __typename?: 'Message', _id: any, content: string | null, createdAt: string | null, status: Types.MessageStatus | null, attachments: Array<{ __typename?: 'MessageAttachment', filename: string, type: Types.MessageAttachmentType, url: string } | null> | null, ownerId: { __typename?: 'Account', _id: any } | null, replyTo: { __typename?: 'Message', content: string | null, attachments: Array<{ __typename?: 'MessageAttachment', filename: string, type: Types.MessageAttachmentType, url: string } | null> | null } | null, reference: { __typename?: 'MessageReference', offerId: { __typename?: 'Offer', _id: any } | null, orderId: { __typename?: 'Order', _id: any } | null, requestId: { __typename?: 'Request', _id: any, adults: number | null, children: number | null, date: string | null, location: { __typename?: 'RequestLocation', description: string, place_id: string } | null, price: { __typename?: 'RequestPrice', value: number, currency: { __typename?: 'Currency', _id: any, code: string | null, name: string | null, symbol: string | null } | null } | null } | null } | null };

export type FindMessagesQueryVariables = Types.Exact<{
  input: Types.InputMaybe<Types.FindMessagesInput>;
}>;


export type FindMessagesQuery = { __typename?: 'Query', findMessages: { __typename?: 'FindMessagesPayload', hasNextPage: boolean | null, nextCreatedAt: string | null, nextId: string | null, total: number | null, items: Array<{ __typename?: 'Message', _id: any, content: string | null, createdAt: string | null, status: Types.MessageStatus | null, attachments: Array<{ __typename?: 'MessageAttachment', filename: string, type: Types.MessageAttachmentType, url: string } | null> | null, ownerId: { __typename?: 'Account', _id: any } | null, replyTo: { __typename?: 'Message', content: string | null, attachments: Array<{ __typename?: 'MessageAttachment', filename: string, type: Types.MessageAttachmentType, url: string } | null> | null } | null, reference: { __typename?: 'MessageReference', offerId: { __typename?: 'Offer', _id: any } | null, orderId: { __typename?: 'Order', _id: any } | null, requestId: { __typename?: 'Request', _id: any, adults: number | null, children: number | null, date: string | null, location: { __typename?: 'RequestLocation', description: string, place_id: string } | null, price: { __typename?: 'RequestPrice', value: number, currency: { __typename?: 'Currency', _id: any, code: string | null, name: string | null, symbol: string | null } | null } | null } | null } | null } | null> | null } | null };

export type SendMessageMutationVariables = Types.Exact<{
  input: Types.InputMaybe<Types.SendMessageInput>;
}>;


export type SendMessageMutation = { __typename?: 'Mutation', sendMessage: { __typename?: 'Message', chatId: string | null, _id: any, content: string | null, createdAt: string | null, status: Types.MessageStatus | null, attachments: Array<{ __typename?: 'MessageAttachment', filename: string, type: Types.MessageAttachmentType, url: string } | null> | null, ownerId: { __typename?: 'Account', _id: any } | null, replyTo: { __typename?: 'Message', content: string | null, attachments: Array<{ __typename?: 'MessageAttachment', filename: string, type: Types.MessageAttachmentType, url: string } | null> | null } | null, reference: { __typename?: 'MessageReference', offerId: { __typename?: 'Offer', _id: any } | null, orderId: { __typename?: 'Order', _id: any } | null, requestId: { __typename?: 'Request', _id: any, adults: number | null, children: number | null, date: string | null, location: { __typename?: 'RequestLocation', description: string, place_id: string } | null, price: { __typename?: 'RequestPrice', value: number, currency: { __typename?: 'Currency', _id: any, code: string | null, name: string | null, symbol: string | null } | null } | null } | null } | null } | null };

export const RequestPriceFragmentDoc = gql`
    fragment RequestPrice on RequestPrice {
  value
  currency {
    ...Currency
  }
}
    ${CurrencyFragmentDoc}`;
export const MessageFragmentDoc = gql`
    fragment Message on Message {
  _id
  attachments {
    filename
    type
    url
  }
  content
  createdAt
  ownerId {
    _id
  }
  replyTo {
    attachments {
      filename
      type
      url
    }
    content
  }
  reference {
    offerId {
      _id
    }
    orderId {
      _id
    }
    requestId {
      _id
      adults
      children
      date
      location {
        description
        place_id
      }
      price {
        ...RequestPrice
      }
    }
  }
  status
}
    ${RequestPriceFragmentDoc}`;
export const FindMessagesDocument = gql`
    query FindMessages($input: FindMessagesInput) {
  findMessages(input: $input) {
    hasNextPage
    items {
      ...Message
    }
    nextCreatedAt
    nextId
    total
  }
}
    ${MessageFragmentDoc}`;

/**
 * __useFindMessagesQuery__
 *
 * To run a query within a React component, call `useFindMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindMessagesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindMessagesQuery(baseOptions?: Apollo.QueryHookOptions<FindMessagesQuery, FindMessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindMessagesQuery, FindMessagesQueryVariables>(FindMessagesDocument, options);
      }
export function useFindMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindMessagesQuery, FindMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindMessagesQuery, FindMessagesQueryVariables>(FindMessagesDocument, options);
        }
export function useFindMessagesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FindMessagesQuery, FindMessagesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindMessagesQuery, FindMessagesQueryVariables>(FindMessagesDocument, options);
        }
export type FindMessagesQueryHookResult = ReturnType<typeof useFindMessagesQuery>;
export type FindMessagesLazyQueryHookResult = ReturnType<typeof useFindMessagesLazyQuery>;
export type FindMessagesSuspenseQueryHookResult = ReturnType<typeof useFindMessagesSuspenseQuery>;
export type FindMessagesQueryResult = Apollo.QueryResult<FindMessagesQuery, FindMessagesQueryVariables>;
export const SendMessageDocument = gql`
    mutation SendMessage($input: SendMessageInput) {
  sendMessage(input: $input) {
    ...Message
    chatId
  }
}
    ${MessageFragmentDoc}`;
export type SendMessageMutationFn = Apollo.MutationFunction<SendMessageMutation, SendMessageMutationVariables>;

/**
 * __useSendMessageMutation__
 *
 * To run a mutation, you first call `useSendMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMessageMutation, { data, loading, error }] = useSendMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendMessageMutation(baseOptions?: Apollo.MutationHookOptions<SendMessageMutation, SendMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendMessageMutation, SendMessageMutationVariables>(SendMessageDocument, options);
      }
export type SendMessageMutationHookResult = ReturnType<typeof useSendMessageMutation>;
export type SendMessageMutationResult = Apollo.MutationResult<SendMessageMutation>;
export type SendMessageMutationOptions = Apollo.BaseMutationOptions<SendMessageMutation, SendMessageMutationVariables>;