import {
  Box,
  Button,
  HStack,
  Text,
  useBreakpoint,
  useDisclosure,
} from '@chakra-ui/react';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useController, useFormContext } from 'react-hook-form';
import get from 'lodash/get';

import { OfferType } from '@app/api/gql/generated-types';
import { Datepicker } from '@app/components/Datepicker';
import { InputNumber } from '@app/components/FormElements/InputNumber';
import { FormField } from '@app/components/formInputs/FormField';
import { PriceCurrencyField } from '@app/components/formInputs/PriceCurrencyField';
import { OfferFragment } from '@app/api/schemas/offer.amazon.generated';

import { OfferPriceLine } from './OfferPriceLine';
import { PriceWithCommission } from './PriceWithCommission';

interface OfferPriceSegmentProps {
  type: OfferType;
  name?: string;
  isFirst?: boolean;
  isDefaultOpen?: boolean;
  onRemove?: () => void;
}

export const OfferPriceSegment: FC<OfferPriceSegmentProps> = ({
  type,
  name = null,
  isFirst,
  isDefaultOpen = false,
  onRemove,
}) => {
  const { onOpen, onClose, isOpen } = useDisclosure({
    defaultIsOpen: isDefaultOpen,
  });
  const { t } = useTranslation(['forms']);
  const {
    control,
    formState: { errors },
    trigger,
    setValue,
    clearErrors,
  } = useFormContext();

  const breakpoint = useBreakpoint({ ssr: false });
  const isMobile = breakpoint === 'sm';

  const fieldNameAppendix = useMemo(() => (name ? `${name}.` : ''), [name]);
  const showSaveButton = !isDefaultOpen;

  const { field: { value: isSavedValue } = {} } = useController({
    name: `${fieldNameAppendix}isSaved`,
    control,
  });

  const { field: { value: offerAddition } = {} } = useController({
    name: `${fieldNameAppendix}`,
    control,
  });

  const { field: { value: commission } = {} } = useController({
    name: 'isCommissionIncluded',
    control,
  });

  const isSavedError = get(errors, `${fieldNameAppendix}isSaved`);

  const hasErrors = useMemo(() => {
    return get(errors, name);
  }, [errors, name]);

  const handleOpen = () => {
    setValue(`${fieldNameAppendix}isSaved`, false);
    onOpen();
  };
  const handleSave = useCallback(async () => {
    const result = await trigger([
      `${fieldNameAppendix}.startDate`,
      `${fieldNameAppendix}.days`,
      `${fieldNameAppendix}.price`,
      `${fieldNameAppendix}.adults`,
      `${fieldNameAppendix}.children`,
    ]);
    if (!result) {
      return;
    }
    setValue(`${fieldNameAppendix}isSaved`, true);
    clearErrors();
    onClose();
  }, [fieldNameAppendix]);

  useEffect(() => {
    if (!isSavedValue) {
      onOpen();
    }
  }, []);

  useEffect(() => {
    if (!!hasErrors) {
      onOpen();
    }
  }, [hasErrors]);

  if (!isOpen) {
    return (
      <OfferPriceLine
        hasCommision={commission}
        isFirst={isFirst}
        item={offerAddition}
        onEdit={handleOpen}
        onRemove={() => onRemove?.()}
      />
    );
  }

  return (
    <Box>
      <Box
        border="1px solid"
        borderColor={!!isSavedError ? 'red' : 'gray.100'}
        borderRadius={6}
        mt={4}
        pt={2}
        px={{ base: 2, md: 4 }}>
        <HStack
          alignItems="flex-start"
          flexWrap={{ base: 'wrap', md: 'nowrap' }}
          justifyContent="space-between"
          spacing={{ base: 1, md: 2, lg: 4 }}>
          <HStack
            alignItems="flex-start"
            justifyContent="space-between"
            spacing={{ base: 1, md: 2, lg: 4 }}>
            <Datepicker
              label={t('startDate')}
              name={`${fieldNameAppendix}startDate`}
              placeholder={t('startDatePlaceholder')}
              placement={isMobile ? 'bottom-start' : 'right-start'}
            />
            <FormField<OfferFragment>
              Component={InputNumber}
              label={t('nights')}
              maxW="100px"
              mb="20px"
              min={1}
              minW={20}
              name={`${fieldNameAppendix}days`}
              placeholder={t('nightsPlaceholder')}
            />
          </HStack>
          {type === OfferType.TOUR && (
            <HStack
              alignItems="flex-start"
              justifyContent="flex-start"
              spacing={4}
              w={{ base: 'auto', md: 'full' }}>
              <FormField<OfferFragment>
                Component={InputNumber}
                label={t('adults')}
                maxW="100px"
                mb="20px"
                min={1}
                name={`${fieldNameAppendix}adults`}
                placeholder={t('adultsChildrenPlaceholder')}
              />
              <FormField<OfferFragment>
                Component={InputNumber}
                label={t('children')}
                maxW="100px"
                mb="20px"
                min={0}
                name={`${fieldNameAppendix}children`}
                placeholder={t('adultsChildrenPlaceholder')}
              />
            </HStack>
          )}
        </HStack>

        <HStack alignItems="flex-start" spacing={4}>
          <PriceCurrencyField name={`${fieldNameAppendix}price`} />
          <PriceWithCommission
            fieldNameAppendix={fieldNameAppendix}
            name={`${fieldNameAppendix}price`}
          />
        </HStack>
        {showSaveButton && (
          <HStack justifyContent="flex-end" mb={2} mt={-2} spacing={4}>
            <HStack justifyContent="flex-end" mb={2} mt={-2} spacing={4}>
              {!isFirst && (
                <Button textDecoration="none" variant="link" onClick={onRemove}>
                  <HStack>
                    <Text fontSize={12}>❌</Text>
                    <Text color="grey.250" fontSize={12}>
                      {t('remove')}
                    </Text>
                  </HStack>
                </Button>
              )}

              <Button
                _disabled={{
                  opacity: 0.5,
                }}
                textDecoration="none"
                variant="link"
                onClick={handleSave}>
                <HStack>
                  <Text fontSize={12}>💾</Text>
                  <Text color="grey.250" fontSize={12}>
                    {t('save')}
                  </Text>
                </HStack>
              </Button>
            </HStack>
          </HStack>
        )}
      </Box>
      <Box color="red" fontSize={14} textAlign="right">
        {!!isSavedError && t('saveForm')}
      </Box>
    </Box>
  );
};
