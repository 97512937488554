import { Flex } from '@chakra-ui/react';
import React from 'react';

import { OrderTable } from './components/Table';

export const Orders = () => {
  return (
    <Flex
      flexDirection="column"
      height={{ base: 'calc(100dvh - 125px)', md: '100dvh' }}
      overflow={{ base: 'auto', md: 'unset' }}
      pt={{ base: 4, md: '30px' }}
      userSelect="none">
      <OrderTable />
    </Flex>
  );
};
