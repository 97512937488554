import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { OfferFragment } from '@app/api/schemas/offer.mongo.generated';

import { PhotoUpload } from './PhotoUpload';

export const Images = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<OfferFragment>();

  const photoError = errors?.photo?.message;

  return (
    <Controller
      control={control}
      name="photo"
      render={({ field: { value, onChange } }) => (
        <PhotoUpload errors={photoError} files={value} setFiles={onChange} />
      )}
    />
  );
};
