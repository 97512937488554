import { Box } from '@chakra-ui/react';
import React, { FC } from 'react';
import { useIntersectionObserver } from 'usehooks-ts';

import { MessageReference } from '@app/api/gql/generated-types';

import OfferRef from './OfferRef';
import { OrderRef } from './OrderRef';
import { RequestRef } from './RequestRef';
import { SkeletonItem } from './Skeleton';

interface MessageReferenceItemProps {
  item: MessageReference;
  isWidget?: boolean;
}

export const MessageReferenceItem: FC<MessageReferenceItemProps> = ({
  item,
  isWidget = false,
}) => {
  const { isIntersecting, ref } = useIntersectionObserver({
    threshold: 1,
    freezeOnceVisible: true,
  });
  const orderId = !!item?.orderId?._id ? item.orderId._id : item?.orderId;
  const offerId = !!item?.offerId?._id ? item?.offerId._id : item?.offerId;
  const requestId = !!item?.requestId?._id
    ? item?.requestId._id
    : item?.requestId;

  return (
    <Box ref={ref}>
      {!isIntersecting ? (
        <SkeletonItem />
      ) : (
        <>
          {!!offerId && <OfferRef id={offerId} isWidget={isWidget} />}
          {!!orderId && <OrderRef id={orderId} isWidget={isWidget} />}
          {!!requestId && <RequestRef id={requestId} isWidget={isWidget} />}
        </>
      )}
    </Box>
  );
};
