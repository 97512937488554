import {
  Avatar,
  Flex,
  Text,
  Box,
  IconButton,
  HStack,
  Tooltip,
} from '@chakra-ui/react';
import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import formatRelative from 'date-fns/formatRelative';
import enGB from 'date-fns/locale/en-GB';
import uk from 'date-fns/locale/uk';
import { Link, useNavigate } from 'react-router';

import { ReactComponent as OpenIcon } from '@app/icons/open-in-new-window.svg';
import { Account } from '@app/api/gql/generated-types';
import { ReactComponent as CloseIcon } from 'icons/close-icon.svg';
import { ReactComponent as HideIcon } from 'icons/hide-icon.svg';
import { ReactComponent as BackIcon } from '@app/icons/come-back-icon.svg';
import { db } from '@app/db';

interface HeaderProps {
  id: string;
  companion: Account;
  title: string;
  isWidget?: boolean;
  onCloseWidget?: () => void;
}

const locale = {
  ...enGB,
  ...uk,
};

const imageServer = process.env.REACT_APP_AMAZON_IMAGE_SERVER;

export const Header: FC<HeaderProps> = ({
  id,
  companion,
  title,
  isWidget = false,
  onCloseWidget,
}) => {
  const { t } = useTranslation(['profile']);
  const navigate = useNavigate();

  const getBackNav = () => navigate(-1);

  const name =
    companion?.fullName ?? `${companion?.firstName} ${companion?.lastName}`;

  const avatar = companion?.avatar?.key
    ? `${imageServer}/${companion?.avatar?.key}?width=200&format=webp`
    : '';

  const lastOnline = useMemo(() => {
    if (companion?.isOnline) {
      return t('online');
    }
    if (!companion?.lastOnlineDate) {
      return t('never');
    }

    return `${t('lastSeen')} ${formatRelative(
      !!companion?.lastOnlineDate
        ? new Date(companion?.lastOnlineDate)
        : new Date(),
      new Date(),
      { locale },
    )}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companion?.isOnline, companion?.lastOnlineDate]);

  const removeChat = useCallback(() => {
    onCloseWidget();
    if (id) {
      db.chats.delete(id);
    }
  }, []);

  return (
    <HStack
      borderBottom={isWidget ? '1px' : 'none'}
      borderColor={isWidget ? 'grey.200' : 'none'}
      gap={2}
      justifyContent="space-between"
      maxW={isWidget ? '340px' : '720px'}
      mx="auto"
      p={{ base: '10px', md: isWidget ? '12px' : '20px' }}
      userSelect="none"
      w="100%">
      <Flex alignItems="center" borderRadius="20px 0 0" flex={1} gap={0}>
        {!isWidget && (
          <IconButton
            alignItems="center"
            aria-label="Back"
            cursor="pointer"
            display={{ base: 'block', md: 'none' }}
            h="25px"
            mr="10px"
            variant="icon"
            w="25px"
            onClick={getBackNav}>
            <BackIcon color="grey.300" />
          </IconButton>
        )}
        <Avatar
          key={id}
          h={
            isWidget
              ? { base: '30px', '2xl': '40px' }
              : { base: '40px', '2xl': '50px' }
          }
          name={name}
          src={avatar}
          w={
            isWidget
              ? { base: '30px', '2xl': '40px' }
              : { base: '40px', '2xl': '50px' }
          }
        />
        <Flex
          alignItems="flex-start"
          flexDirection="column"
          gap="2px"
          justify="center"
          mx={isWidget ? 2 : 5}>
          <Text fontSize="sm" fontWeight="bold" noOfLines={1}>
            {title}
          </Text>
          <Flex alignItems="center" gap={2} justify="center">
            <Box
              background={companion?.isOnline ? 'primary.green' : 'grey.300'}
              borderRadius="50%"
              h="10px"
              w="10px"
            />{' '}
            <Tooltip
              label={lastOnline}
              offset={[0, 0]}
              placement="top"
              variant="ligth">
              <Text color="grey.300" fontSize="12px" noOfLines={1}>
                {lastOnline}
              </Text>
            </Tooltip>
          </Flex>
        </Flex>
      </Flex>
      {isWidget && (
        <HStack>
          <IconButton
            alignItems="center"
            aria-label="Close"
            as={Link}
            color="grey.300"
            cursor="pointer"
            size="xs"
            to={`/chat/${id}`}
            variant="icon">
            <OpenIcon width="15px" />
          </IconButton>
          <IconButton
            alignItems="center"
            aria-label="Close"
            color="grey.300"
            cursor="pointer"
            size="xs"
            variant="icon"
            onClick={onCloseWidget}>
            <HideIcon width="15px" />
          </IconButton>
          <IconButton
            alignItems="center"
            aria-label="Close"
            color="grey.300"
            cursor="pointer"
            size="xs"
            variant="icon"
            onClick={removeChat}>
            <CloseIcon width="20px" />
          </IconButton>
        </HStack>
      )}
    </HStack>
  );
};
