import {
  FormControl,
  Input,
  InputGroup,
  InputRightAddon,
} from '@chakra-ui/react';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import get from 'lodash/get';

import { ErrorMessage } from '@app/components/FormElements/ErrorMessage';
import { OfferFragment } from '@app/api/schemas/offer.mongo.generated';
import { useCurrenciesQuery } from '@app/api/schemas/currency.amazon.generated';

import { RightAddonSelect } from '../SelectField/RightAddonSelect';
import Label from '../FormElements/Label';

interface PriceCurrencyFieldProps {
  name: string;
}

export const PriceCurrencyField: FC<PriceCurrencyFieldProps> = ({
  name = 'price',
}) => {
  const { data: { currencies } = {} } = useCurrenciesQuery({
    variables: {
      filter: {
        items: {
          fieldName: 'enabled',
          value: 'true',
          filterOption: 'Equals',
        },
      },
      pagination: {
        limit: 300,
      },
    },
    context: {
      clientName: 'amazon',
    },
  });

  const items = useMemo(() => currencies?.items ?? [], [currencies?.items]);

  const {
    register,
    control,
    formState: { errors, isValid },
  } = useFormContext<OfferFragment>();

  const error = get(errors, name);

  const isCurrencyInvalid = !!error?.currency && !isValid;
  const isValueInvalid = !!error?.value && !isValid;

  const { t } = useTranslation(['tables']);
  return (
    <FormControl
      isInvalid={isCurrencyInvalid || isValueInvalid}
      maxW="150px"
      minW="130px"
      pb="25px"
      position="relative"
      role="group">
      <Label color="grey.300" fontSize={14}>
        {t('price')}
      </Label>
      <InputGroup
        _focus={{
          borderColor: 'primary.green',
        }}
        _groupFocus={{
          borderColor: 'primary.green',
        }}
        border="1px solid"
        borderColor={isValueInvalid ? 'red.600' : 'gray.100'}
        borderRadius="10px"
        h="40px"
        isolation="auto"
        variant="unstyled">
        <Input
          border="0 none"
          h="38px"
          isInvalid={isValueInvalid}
          name={`${name}.value`}
          placeholder={t('pricePlaceholder')}
          type="number"
          {...register(`${name}.value`)}
          variant="main"
        />
        <InputRightAddon>
          <Controller
            control={control}
            name={`${name}.currency`}
            render={({ field }) => (
              <RightAddonSelect
                getOptionLabel={(currency) => currency?.code}
                getOptionValue={(currency) => currency?._id}
                {...field}
                options={items}
              />
            )}
          />
        </InputRightAddon>
      </InputGroup>
      <ErrorMessage
        bottom="6px"
        color="red.600"
        display={{ base: 'none', md: 'block' }}
        fontSize="12px"
        fontWeight={400}
        left={1}
        maxH="16px"
        noOfLines={1}
        position="absolute">
        {error?.value?.message || error?.currency?.message}
      </ErrorMessage>
    </FormControl>
  );
};
