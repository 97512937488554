import floor from 'lodash/floor';

import {
  PaymentProcessorName,
  PaymentProviderStatus,
} from '@app/api/gql/generated-types-amazon';
import { OfferPrice } from '@app/api/gql/generated-types';

import useCurrentAccount from './useCurrentAccount';

export const serviceCommision = 5;
export const providerCommisions = {
  [PaymentProcessorName.MONOBANK]: 1.3,
  [PaymentProcessorName.ROZETKAPAY]: 1.5,
};

export const useCalculateCommision = ({
  price,
  commission,
  inCents = true,
}: {
  price: OfferPrice;
  commission: boolean;
  inCents: boolean;
}) => {
  const { business } = useCurrentAccount();
  const hasCommision = commission;

  const provider =
    business?.paymentProviderStatus === PaymentProviderStatus.ACTIVE
      ? providerCommisions?.[business?.paymentProcessorName]
      : 0;

  const priceVal = inCents ? Number(price?.value ?? 0) * 100 : price?.value;

  const serviceFee = {
    ...price,
    value: hasCommision
      ? floor(
          (priceVal - priceVal * (provider / 100)) * (serviceCommision / 100),
          0,
        )
      : floor(priceVal / (1 - serviceCommision / 100) - priceVal, 0),
  };

  const providerFee = {
    ...price,
    value: hasCommision
      ? floor(priceVal * (provider / 100), 0)
      : floor(
          (priceVal + serviceFee.value) / (1 - provider / 100) -
            (priceVal + serviceFee.value),
          0,
        ),
  };

  const fullPrice = {
    ...price,
    value: hasCommision
      ? priceVal
      : priceVal + providerFee.value + serviceFee.value,
  };

  if (!price?.value) {
    return {
      fullPrice: {
        value: 0,
        currency: {},
      },
    };
  }

  return {
    serviceFee,
    providerFee,
    fullPrice,
  };
};
