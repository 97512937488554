import { HStack, Text, VStack } from '@chakra-ui/react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { BSON } from 'realm-web';

import { DateFormatter } from '@app/components/DateFormatter';
import { RequestPrice } from '@app/components/PriceField/RequestPrice';
import { useRequestQuery } from '@app/api/schemas/request.mongo.generated';

import { SkeletonItem } from './Skeleton';

interface OfferRefProps {
  id: string;
}

export const RequestRef: FC<OfferRefProps> = ({ id }) => {
  const { t } = useTranslation('tables');
  const { data: { request: item } = {}, loading } = useRequestQuery({
    variables: {
      query: {
        _id: new BSON.ObjectID(id),
      },
    },
  });
  const navigate = useNavigate();

  if (loading) {
    return <SkeletonItem />;
  }

  if (!item) {
    return;
  }

  return (
    <HStack
      justifyContent="space-between"
      spacing={3}
      w="full"
      onClick={() => navigate(`/requests/${item?._id}`)}>
      {!!item?.date && (
        <VStack alignItems="flex-start" spacing={0}>
          <Text
            color="grey.250"
            fontSize={12}
            fontWeight={600}
            whiteSpace="nowrap">
            {t('tripDate')}
          </Text>
          <Text color="grey.300" fontWeight={500}>
            <DateFormatter date={new Date(item?.date)} format="LLL, yyyy" />
          </Text>
        </VStack>
      )}

      {!!item?.price?.value && (
        <VStack alignItems="flex-start" spacing={0}>
          <Text
            color="grey.250"
            fontSize={12}
            fontWeight={600}
            whiteSpace="nowrap">
            {t('amount')}
          </Text>
          <Text color="grey.300" fontWeight={500}>
            <RequestPrice value={item?.price} />
          </Text>
        </VStack>
      )}
      <VStack alignItems="center" spacing={0}>
        <Text
          color="grey.250"
          fontSize={12}
          fontWeight={600}
          whiteSpace="nowrap">
          {t('adults')}
        </Text>
        <Text color="grey.300" fontWeight={500}>
          {item?.adults}
        </Text>
      </VStack>
      {!!Number(item?.children) && (
        <VStack alignItems="center" spacing={0}>
          <Text
            color="grey.250"
            fontSize={12}
            fontWeight={600}
            whiteSpace="nowrap">
            {t('children')}
          </Text>
          <Text color="grey.300" fontWeight={500}>
            {item.children}
          </Text>
        </VStack>
      )}
    </HStack>
  );
};
