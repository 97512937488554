import * as Types from '@/gql/__generated__/types';

import { gql } from '@apollo/client';
import { ActivityFragmentDoc } from './activity.amazon.generated';
import { CharacteristicFragmentDoc } from './travelerCharacteristics.amazon.generated';
import { CurrencyFragmentDoc } from './currency.amazon.generated';
export type RequestFragment = { __typename?: 'Request', _id: string | null, adults: number | null, children: number | null, createdAt: string | null, date: string | null, ownerId: string | null, status: Types.RequestStatus | null, description: string | null, updatedAt: string | null, childrenAge: Array<number> | null, startDate: string | null, endDate: string | null, nights: number | null, periodDays: number | null, location: { __typename?: 'RequestLocationData', description: string | null, place_id: string | null } | null, owner: { __typename?: 'Account', _id: string, ownerId: string | null, fullName: string | null, firstName: string | null, lastName: string | null, activities: Array<{ __typename?: 'Activity', _id: string, color: string | null, parent: { __typename?: 'Activity', _id: string } | null, details: Array<{ __typename?: 'ActivitiesDetail', lang: string | null, title: string | null }> | null }> | null, travelerCharacteristics: Array<{ __typename?: 'TravelerCharacteristic', _id: string, parent: { __typename?: 'TravelerCharacteristic', _id: string } | null, details: Array<{ __typename?: 'TravelerCharacteristicsDetail', lang: string | null, title: string | null }> | null }> | null } | null, price: { __typename?: 'PriceDetail', value: number | null, currency: { __typename?: 'Currency', _id: string, code: string | null, name: string | null, symbol: string | null, isoCode: string | null } | null } | null, locationStart: { __typename?: 'RequestLocationData', description: string | null, place_id: string | null } | null };

export const RequestFragmentDoc = gql`
    fragment Request on Request {
  _id
  adults
  children
  createdAt
  date
  location {
    description
    place_id
  }
  owner {
    _id
    ownerId
    fullName
    firstName
    lastName
    activities {
      ...Activity
    }
    travelerCharacteristics {
      ...Characteristic
    }
  }
  ownerId
  price {
    value
    currency {
      ...Currency
    }
  }
  status
  description
  updatedAt
  locationStart {
    description
    place_id
  }
  childrenAge
  startDate
  endDate
  nights
  periodDays
}
    ${ActivityFragmentDoc}
${CharacteristicFragmentDoc}
${CurrencyFragmentDoc}`;