import { Box, Text, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { PriceField } from '@app/components/PriceField';
import useCurrentAccount from '@app/hooks/useCurrentAccount';

export const BalanceBlock = () => {
  const { t } = useTranslation('modalsAndMenus');

  const { business } = useCurrentAccount();
  const balance = {
    value: business?.balance ? business.balance.toFixed(0) : 0,
    currency: {
      code: 'UAH',
    },
  };

  const isNotEnoughBalance = business.balance < 0;

  return (
    <Box
      backgroundColor={isNotEnoughBalance ? '#fEEAE9' : 'mainGray.200'}
      borderRadius={10}
      minW={10}
      p="5px"
      transition="all 0.15s">
      <Text
        color={isNotEnoughBalance ? 'red' : 'mainGray.100'}
        fontSize={14}
        fontWeight={600}
        textAlign="center">
        <Tooltip
          hasArrow
          bg="#EA7B34"
          borderRadius="10px"
          colorScheme="red"
          isDisabled={!isNotEnoughBalance}
          label={
            <Text
              bg="#EA7B34"
              borderRadius="10px"
              color="white"
              fontSize="13px"
              fontWeight="500"
              maxW="150px"
              padding="10px 10px">
              {t('notEnoughBalance')}
            </Text>
          }
          padding="0"
          placement="top">
          <Box>
            <PriceField value={balance} />
          </Box>
        </Tooltip>
      </Text>
    </Box>
  );
};
