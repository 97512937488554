import * as Types from '@/gql/__generated__/types';

import { gql } from '@apollo/client';
export type ActivityFragment = { __typename?: 'Activity', _id: string, color: string | null, parent: { __typename?: 'Activity', _id: string } | null, details: Array<{ __typename?: 'ActivitiesDetail', lang: string | null, title: string | null }> | null };

export const ActivityFragmentDoc = gql`
    fragment Activity on Activity {
  _id
  color
  parent {
    _id
  }
  details {
    lang
    title
  }
}
    `;