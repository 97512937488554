import { Box, Button, Flex, HStack, Heading, Text } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router';
import { signInWithRedirect } from 'aws-amplify/auth';

import { HSeparator } from '@app/components/Separator';
import { ReactComponent as GoogleIcon } from '@app/icons/google-icon.svg';
import { useErrorNotification } from '@app/hooks/useErrorNotification';

import { LoginForm } from './components/LoginForm';

export const Login = () => {
  const errorNotify = useErrorNotification();
  const { t } = useTranslation(['forms', 'addOffer']);
  const handleSignInGoogle = async () => {
    try {
      await signInWithRedirect({ provider: 'Google' });
    } catch (error) {
      errorNotify(error);
    }
  };
  return (
    <>
      <Heading color="grey.300" fontSize="34px" fontWeight={600} mb="5px">
        {t('signIn')}
      </Heading>
      <Flex
        alignItems="start"
        flexDirection="column"
        justifyContent="center"
        maxW="100%"
        mb="20px">
        <Text color="grey.250" fontSize="16px" fontWeight="400">
          {t('notRegisteredYet')}

          <Button
            as={NavLink}
            fontSize="16px"
            fontWeight="500"
            ms="5px"
            to="/sign-up"
            variant="link">
            {t('createAnAccount')}
          </Button>
        </Text>
      </Flex>
      <Box w="full">
        <LoginForm />
      </Box>
      <Flex align="center" my="25px" w="full">
        <HSeparator backgroundColor="grey.250" />
        <Text color="grey.250" mx="14px" textAlign="center">
          {t('or')}
        </Text>
        <HSeparator backgroundColor="grey.250" />
      </Flex>
      <HStack justifyContent="center" mb={8} w="full">
        <Button variant="gray" onClick={handleSignInGoogle}>
          <HStack>
            <Text color="white">{t('loginWith')}</Text>
            <GoogleIcon />
          </HStack>
        </Button>
      </HStack>
    </>
  );
};
