import { Box, HStack, Image, VStack } from '@chakra-ui/react';
import React from 'react';
import { Link, Outlet } from 'react-router';

import { PhotoBar } from '@app/components/Auth/PhotoBar';
import { useColorMode } from '@app/hooks/useColorMode';
import { AuthFooter } from '@app/components/Auth/Footer';
import { SelectAppLanguage } from '@app/components/SelectAppLanguage';

const LoginLayout = () => {
  const { bgColor } = useColorMode();

  return (
    <Box
      backgroundColor={bgColor}
      height="100%"
      maxHeight="100dvh"
      maxWidth="100%"
      overflow="hidden"
      overflowY="hidden"
      position="relative">
      <HStack>
        <Box display={['none', 'none', 'none', 'block']} flex={1} h="100dvh">
          <PhotoBar />
        </Box>
        <Box flex={1} h="100dvh" overflowY="auto" px={4} w="full">
          <VStack
            alignItems="flex-start"
            justifyContent="center"
            maxW="400px"
            minH="full"
            mx="auto"
            position="relative"
            py={10}>
            <Outlet />
            <Box
              bottom={4}
              display={['block', 'block', 'block', 'none']}
              position="absolute">
              <AuthFooter color="grey.250" />
            </Box>
          </VStack>
        </Box>
      </HStack>
      <HStack position="absolute" right="30px" spacing={5} top="30px">
        <SelectAppLanguage />
        <Link to="/">
          <Image maxH="50px" src="/logo.png" />
        </Link>
      </HStack>
    </Box>
  );
};

export default LoginLayout;
