import { Box, HStack, Text, Tooltip, VStack } from '@chakra-ui/react';
import React, { FC } from 'react';
import { useController, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useCalculateCommision } from '@app/hooks/useCalculateCommision';
import Label from '@app/components/FormElements/Label';
import { PriceField } from '@app/components/PriceField';
import { ReactComponent as Icon } from '@app/icons/check-circle-icon.svg';

interface PriceWithCommissionProps {
  name: string;
}

export const PriceWithCommission: FC<PriceWithCommissionProps> = ({
  name = 'price',
}) => {
  const { t } = useTranslation('forms');
  const { control } = useFormContext();

  const price = useWatch<OfferFragment>({ name, control });

  const { field: { value: commission } = {} } = useController({
    name: 'isCommissionIncluded',
    control,
  });

  const { serviceFee, providerFee, fullPrice } = useCalculateCommision({
    price,
    commission,
  });

  if (!price.value || !price?.currency) {
    return null;
  }

  return (
    <Box flex={1}>
      <Label color="grey.300" fontSize={14} htmlFor={name}>
        {t('totalPrice')}
      </Label>
      <Box h={10} mx={1}>
        <Tooltip
          label={
            <VStack alignItems="flex-start" py={1}>
              <HStack justifyContent="flex-start" spacing={2} w="full">
                <Text fontWeight={600}>
                  <PriceField value={serviceFee} />
                </Text>
                <Text color="grey.250" fontWeight={500}>
                  {t('commission.mira')}
                </Text>
              </HStack>
              {!!providerFee?.value && (
                <HStack justifyContent="flex-start" spacing={2}>
                  <Text fontWeight={600}>
                    <PriceField value={providerFee} />
                  </Text>
                  <Text color="grey.250" fontWeight={500}>
                    {t('commission.provider')}
                  </Text>
                </HStack>
              )}
            </VStack>
          }
          offset={[0, -5]}
          placement="bottom-start"
          variant="ligth">
          <HStack alignItems="center" h={10} spacing={2}>
            <Text color="grey.300" fontWeight={600}>
              <PriceField value={fullPrice} />
            </Text>

            <HStack
              color="primary.green"
              display={{ base: 'none', md: 'flex' }}
              spacing={1}>
              <Icon height={14} width={14} />
              <Text color="grey.250">{t('commission.includedTitle')}</Text>
            </HStack>
          </HStack>
        </Tooltip>
      </Box>
    </Box>
  );
};
