import React, { FC, useMemo } from 'react';
import { HStack, Image, Text, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { addDays } from 'date-fns';

import { DateFormatter } from '@app/components/DateFormatter';
import { PriceField } from '@app/components/PriceField';
import { useOfferQuery } from '@app/api/schemas/offer.amazon.generated';

import { SkeletonItem } from './Skeleton';

interface OfferRefProps {
  id: string;
  isWidget?: boolean;
}

const imageServer = process.env.REACT_APP_AMAZON_IMAGE_SERVER;

const OfferRef: FC<OfferRefProps> = ({ id, isWidget = false }) => {
  const { t } = useTranslation('tables');
  const { data: { offer: item } = {}, loading } = useOfferQuery({
    variables: {
      id,
    },
    context: {
      clientName: 'amazon',
    },
  });
  const navigate = useNavigate();
  const getEndDate = useMemo(() => {
    if (!item?.startDate && !item?.days) {
      return;
    }
    return addDays(new Date(item?.startDate), Number(item?.days));
  }, [item?.days, item?.startDate]);

  if (loading) {
    return <SkeletonItem />;
  }

  if (!item) {
    return;
  }

  return (
    <HStack
      spacing={isWidget ? 2 : 3}
      onClick={() => navigate(`/offers/${item?._id}`)}>
      <Image
        borderRadius={8}
        height="50px"
        objectFit="cover"
        src={`${imageServer}/${item?.photo?.[0]?.key}?width=100&format=webp`}
        width="50px"
      />
      <VStack alignItems="flex-start" spacing={0}>
        <Text color="grey.300" fontWeight={600} noOfLines={1}>
          {item?.title}
        </Text>
        {!!item?.startDate && getEndDate && (
          <Text color="grey.300" fontSize={12} noOfLines={1}>
            <DateFormatter
              date={new Date(item?.startDate)}
              format={isWidget ? 'P' : 'PP'}
            />{' '}
            - <DateFormatter date={getEndDate} format={isWidget ? 'P' : 'PP'} />
          </Text>
        )}
      </VStack>

      {!isWidget && (
        <VStack alignItems="center" spacing={0}>
          <Text
            color="grey.250"
            fontSize={12}
            fontWeight={600}
            whiteSpace="nowrap">
            {t('nights')}
          </Text>
          <Text color="grey.300" fontWeight={500}>
            {item?.days}
          </Text>
        </VStack>
      )}
      <VStack alignItems="flex-start" spacing={0}>
        <Text
          color="grey.250"
          fontSize={12}
          fontWeight={600}
          whiteSpace="nowrap">
          {t('price')}
        </Text>
        <Text color="grey.300" fontWeight={500}>
          <PriceField
            value={
              item?.price?.value
                ? item?.price
                : { ...item?.price?.currency, value: 0 }
            }
          />
        </Text>
      </VStack>
    </HStack>
  );
};

export default OfferRef;
