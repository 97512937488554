import { HStack, Image, Text, IconButton } from '@chakra-ui/react';
import React, { useContext } from 'react';
import { Link } from 'react-router';
import { useTranslation } from 'react-i18next';

import { SidebarContext } from '@app/contexts/SidebarContext';
import { ReactComponent as CloseIcon } from '@app/icons/close-icon.svg';

import { AddOfferButton } from '../../buttons/AddOfferButton';

export const SidebarBrand = () => {
  const { t } = useTranslation('navbar');
  const { toggleSidebar, onCloseSidebar } = useContext(SidebarContext);
  return (
    <HStack
      alignItems="center"
      justifyContent="space-between"
      spacing={5}
      w="100%">
      <HStack
        flex={1}
        justifyContent={{ base: 'flex-start', md: 'space-between' }}>
        <HStack alignItems="center" as={Link} flex={1} to="/">
          {toggleSidebar && <Image maxH="50px" src="/logo.png" />}
          {toggleSidebar && (
            <Text fontSize={'16px'} fontWeight={600}>
              {t('logoTitle')}
            </Text>
          )}
        </HStack>
        <AddOfferButton />
      </HStack>
      <IconButton
        alignItems="center"
        aria-label="Close"
        color="grey.300"
        cursor="pointer"
        display={{ base: 'flex', md: 'none' }}
        size="md"
        variant="icon"
        onClick={onCloseSidebar}>
        <CloseIcon width="24px" />
      </IconButton>
    </HStack>
  );
};
