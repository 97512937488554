import { Heading, Text } from '@chakra-ui/react';
import React, { KeyboardEvent, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { resetPassword } from 'aws-amplify/auth';
import { useNavigate } from 'react-router';

import { FormField } from '@app/components/formInputs/FormField';
import { useColorMode } from '@app/hooks/useColorMode';
import { Submit } from '@app/views/Login/components/LoginForm/Submit';
import { useErrorNotification } from '@app/hooks/useErrorNotification';

import { SignInLink } from '../SignUp/components/SignInLink';

import { ForgotFormProps, initialValues } from './initialValues';
import { useValidationSchema } from './useValidationSchema';

export const ForgotPassword = () => {
  const toast = useErrorNotification();
  const { textColor } = useColorMode();
  const resolver = useValidationSchema();
  const { t } = useTranslation('forms');
  const navigate = useNavigate();

  const onSubmit = useCallback(async (values: ForgotFormProps) => {
    try {
      const { nextStep } = await resetPassword({ username: values.email });
      if (nextStep.resetPasswordStep === 'CONFIRM_RESET_PASSWORD_WITH_CODE') {
        navigate('/new-password', { state: { username: values.email } });
      }
    } catch (err) {
      toast(err);
    }
  }, []);
  const form = useForm({
    defaultValues: initialValues,
    resolver,
  });

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      void form.handleSubmit(onSubmit)();
    }
  };

  return (
    <>
      <Heading color={textColor} fontSize="36px" fontWeight={600} mb="10px">
        {t('forgotPassword')}
      </Heading>
      <Text color="grey.250" fontSize="16px" fontWeight={400} mb="20px">
        {t('noProblem')}
      </Text>
      <FormProvider {...form}>
        <FormField
          isRequired
          fontSize="sm"
          mb="25px"
          ms={{ base: '0px', md: '0px' }}
          name="email"
          placeholder={t('email')}
          size="lg"
          type="email"
          variant="auth"
          onKeyDown={onKeyDown}
        />
        <Submit title={t('getResetLink')} onSubmit={onSubmit} />
      </FormProvider>
      <SignInLink />
    </>
  );
};
