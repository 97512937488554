import React, { lazy } from 'react';
import { Navigate, NonIndexRouteObject } from 'react-router';

import { SuspenseSkeletonWrapper } from '@app/components/SuspenseSkeletonWrapper';
import { AgencyInformationPage } from '@app/views/AgencyInformationPage';
import { ProfileInformationPage } from '@app/views/ProfileInformationPage';
import { VerificationPage } from '@app/views/VerificationPage';
import { ConfirmEmail } from '@app/views/VerificationPage/ConfirmEmail';

import LoginLayout from './layouts/LoginLayout';
import { MainLayout } from './layouts/MainLayout';
import { ForgotPassword } from './views/ForgotPassword';
import { Login } from './views/Login';
import { Logout } from './views/Logout';
import { Orders } from './views/Orders';
import { PageNotFoundView } from './views/PageNotFoundView';
import { SignUp } from './views/SignUp';
import { NewPassword } from './views/ForgotPassword/NewPassword';
import { Restricted } from './views/Restricted';
import { SignUpAgents } from './views/SignUp/SignUpAgents';

const Requests = lazy(() =>
  import('@app/views/Requests').then((m) => ({ default: m.Requests })),
);
const AddOffer = lazy(() =>
  import('@app/views/AddOffer').then((m) => ({ default: m.AddOffer })),
);

const Offers = lazy(() =>
  import('@app/views/Offers').then((m) => ({
    default: m.Offers,
  })),
);
const ProfileSettings = lazy(() =>
  import('@app/views/Settings/content/ProfileSettings').then((m) => ({
    default: m.ProfileSettings,
  })),
);
// const MainSettings = lazy(() =>
//   import('@app/views/Settings/content/MainSettings').then((m) => ({
//     default: m.MainSettings,
//   })),
// );
const Onboarding = lazy(() =>
  import('@app/views/Onboarding').then((m) => ({
    default: m.Onboarding,
  })),
);
const PaymentTransactions = lazy(() =>
  import('@app/views/PaymentTransactions').then((m) => ({
    default: m.PaymentTransactions,
  })),
);
// const GeneralSettings = lazy(() =>
//   import('@app/views/Settings/content/GeneralSettings').then((m) => ({
//     default: m.GeneralSettings,
//   })),
// );
const CurrencyConverter = lazy(() =>
  import('@app/views/Settings/content/CurrencyConverter').then((m) => ({
    default: m.CurrencyConverter,
  })),
);
const BalancePage = lazy(() =>
  import('@app/views/Settings/content/Balance').then((m) => ({
    default: m.Balance,
  })),
);
const BalanceTransactions = lazy(() =>
  import('@app/views/BalanceTransactions').then((m) => ({
    default: m.BalanceTransactions,
  })),
);
const BusinessSettings = lazy(() =>
  import('@app/views/Settings/content/BusinessSettings').then((m) => ({
    default: m.BusinessSettings,
  })),
);

const Chat = lazy(() =>
  import('@app/views/Chat').then((m) => ({
    default: m.ChatView,
  })),
);
const Agents = lazy(() =>
  import('@app/views/Agents').then((m) => ({
    default: m.Agents,
  })),
);
const Clients = lazy(() =>
  import('@app/views/Clients').then((m) => ({
    default: m.Clients,
  })),
);

const SendPush = lazy(() =>
  import('@app/views/Admin/content/Notifications/SendPush').then((m) => ({
    default: m.SendPush,
  })),
);

const MainSettings = lazy(() =>
  import('@app/views/Settings/content/MainSettings').then((m) => ({
    default: m.MainSettings,
  })),
);

export const mainRoutes: NonIndexRouteObject = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      index: true,
      element: <Navigate replace to="/requests" />,
    },
    {
      path: '/requests/:id?',
      element: (
        <SuspenseSkeletonWrapper>
          <Requests />
        </SuspenseSkeletonWrapper>
      ),
    },
    {
      path: '/offers/create',
      element: (
        <SuspenseSkeletonWrapper>
          <AddOffer key="add" />
        </SuspenseSkeletonWrapper>
      ),
    },
    {
      path: '/offers/edit/:id',
      element: (
        <SuspenseSkeletonWrapper>
          <AddOffer key="edit" />
        </SuspenseSkeletonWrapper>
      ),
    },
    {
      path: '/offers/:id?',
      element: (
        <SuspenseSkeletonWrapper>
          <Offers />
        </SuspenseSkeletonWrapper>
      ),
    },
    {
      path: '/settings',
      element: (
        <SuspenseSkeletonWrapper>
          <MainSettings />
        </SuspenseSkeletonWrapper>
      ),
    },
    {
      path: '/settings/payments',
      element: (
        <SuspenseSkeletonWrapper>
          <PaymentTransactions />
        </SuspenseSkeletonWrapper>
      ),
    },
    {
      path: '/settings/payments/onboarding',
      element: (
        <SuspenseSkeletonWrapper>
          <Onboarding />
        </SuspenseSkeletonWrapper>
      ),
    },

    {
      path: '/settings/currency',
      element: (
        <SuspenseSkeletonWrapper>
          <CurrencyConverter />
        </SuspenseSkeletonWrapper>
      ),
    },

    {
      path: '/settings/balance',
      element: (
        <SuspenseSkeletonWrapper>
          <BalanceTransactions />
        </SuspenseSkeletonWrapper>
      ),
    },
    {
      path: '/settings/balance/add',
      element: (
        <SuspenseSkeletonWrapper>
          <BalancePage />
        </SuspenseSkeletonWrapper>
      ),
    },
    {
      path: '/settings/profile',
      element: (
        <SuspenseSkeletonWrapper>
          <ProfileSettings />
        </SuspenseSkeletonWrapper>
      ),
    },
    {
      path: '/settings/business',
      element: (
        <SuspenseSkeletonWrapper>
          <BusinessSettings />
        </SuspenseSkeletonWrapper>
      ),
    },
    // {
    //   path: '/settings/general',
    //   element: (
    //     <SuspenseSkeletonWrapper>
    //       <GeneralSettings />
    //     </SuspenseSkeletonWrapper>
    //   ),
    // },

    {
      path: '/chat/:id?',
      element: (
        <SuspenseSkeletonWrapper>
          <Chat />
        </SuspenseSkeletonWrapper>
      ),
    },
    {
      path: '/orders/:id?',
      element: (
        <SuspenseSkeletonWrapper>
          <Orders />
        </SuspenseSkeletonWrapper>
      ),
    },

    {
      path: '/successPayment',
      element: <Navigate replace to="/settings/balance" />,
    },
    { path: '*', element: <Navigate replace to="/404" /> },
    { path: '/404', element: <PageNotFoundView /> },
  ],
};

export const authRoutes = {
  element: <LoginLayout />,
  path: '/',
  children: [
    { path: '/', element: <Login /> },
    { path: '/sign-up', element: <SignUp /> },
    { path: '/forgot', element: <ForgotPassword /> },
    { path: '/verification', element: <VerificationPage /> },
    { path: '/new-password', element: <NewPassword /> },
    { path: '/confirm', element: <ConfirmEmail /> },
  ],
};

export const signUpRouteAgents = {
  element: <SignUpAgents />,
  path: '/sign-up-agent',
};

export const guestRoutes = {
  element: <LoginLayout />,
  path: '/',
  children: [{ path: '/', element: <Restricted /> }],
};

export const accountInfoRoute = {
  element: <LoginLayout />,
  path: '/',
  children: [{ path: '/', element: <ProfileInformationPage /> }],
};
export const businessInfoRoute = {
  element: <LoginLayout />,
  path: '/',
  children: [{ path: '/', element: <AgencyInformationPage /> }],
};

export const adminRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/admin',
      element: <Navigate replace to="/admin/agents" />,
    },
    {
      path: '/admin/notifications/push',
      element: (
        <SuspenseSkeletonWrapper>
          <SendPush />
        </SuspenseSkeletonWrapper>
      ),
    },
    {
      path: '/admin/agents/:id?',
      element: (
        <SuspenseSkeletonWrapper>
          <Agents />
        </SuspenseSkeletonWrapper>
      ),
    },
    {
      path: '/admin/clients/:id?',
      element: (
        <SuspenseSkeletonWrapper>
          <Clients />
        </SuspenseSkeletonWrapper>
      ),
    },
  ],
};

export const systemRoutes = {
  element: <LoginLayout />,
  path: '/',
  children: [
    { element: <Logout />, path: '/logout' },
    { path: '*', element: <Navigate to="/" /> },
  ],
};
