import { Box, Text, Tooltip, VStack } from '@chakra-ui/react';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router';

import { ReactComponent as BusinessSettingsIcon } from '@app/icons/new-business-settings-icon.svg';
import { ReactComponent as CurrencySettingsIcon } from '@app/icons/currency-revenue-icon.svg';
import { ReactComponent as ProfileSettingsIcon } from '@app/icons/new-profile-settings-icon.svg';
import { ReactComponent as OffersIcon } from '@app/icons/new-offers-icon.svg';
import { ReactComponent as OrdersIcon } from '@app/icons/new-orders-icon.svg';
import { ReactComponent as ChatsIcon } from '@app/icons/new-chats-icon.svg';
import { ReactComponent as RequestsIcon } from '@app/icons/new-requests-icon.svg';
import { ReactComponent as SettingsIcon } from '@app/icons/new-settings-icon.svg';
import { ReactComponent as PaymentSetupIcon } from '@app/icons/payment-card-icon.svg';
import { useWatchData } from '@app/hooks/useWatchData';
import { useAccountIsAdmin } from '@app/hooks/useAccountIsAdmin';
import { SidebarContext } from '@app/contexts/SidebarContext';
import useCurrentAccount from '@app/hooks/useCurrentAccount';

import { ListItem } from './ListItem';
import { EmojiIcon } from './EmojiIcon';
import { BalanceBlock } from './BalanceBlock';

export const SidebarLinks = () => {
  const { isAdmin } = useAccountIsAdmin();
  const { requests, orders, chats } = useWatchData();
  const { toggleSidebar } = useContext(SidebarContext);
  const { business } = useCurrentAccount();

  const { t } = useTranslation('navbar');

  const adminChild = [];
  const needAttention = useMemo(
    () => !business?.paymentProcessorToken,
    [business?.paymentProcessorToken],
  );

  const links = [
    {
      name: t('requests'),
      to: '/requests',
      icon: <RequestsIcon />,
      count: requests,
    },
    {
      name: t('offers'),
      to: '/offers',
      icon: <OffersIcon />,
    },
    {
      name: t('orders'),
      to: '/orders',
      icon: <OrdersIcon />,
      count: orders,
    },

    {
      name: t('chat'),
      to: '/chat',
      icon: <ChatsIcon />,
      count: chats,
    },
    {
      name: t('settings'),
      to: '/settings',
      icon: <SettingsIcon />,
      isNeedAttention: needAttention,
    },
  ];

  const settingsChild = [
    {
      name: t('profile'),
      to: '/settings/profile',
      icon: <ProfileSettingsIcon />,
    },
    {
      name: t('business'),
      to: '/settings/business',
      icon: <BusinessSettingsIcon />,
    },
    {
      name: t('payments'),
      to: '/settings/payments',
      icon: <PaymentSetupIcon height={25} width={25} />,
      isNeedAttention: needAttention,
    },
    {
      name: t('balance'),
      to: '/settings/balance',
      icon: <PaymentSetupIcon height={25} width={25} />,
      custom: <BalanceBlock />,
    },
    {
      name: t('currencyConverter'),
      to: '/settings/currency',
      icon: <CurrencySettingsIcon height={25} width={25} />,
    },
  ];

  if (isAdmin) {
    links.push({
      name: t('admin'),
      to: '/admin',
      icon: (
        <EmojiIcon>
          <Text fontSize={18}>🛠️</Text>
        </EmojiIcon>
      ),
    });

    adminChild.push({
      name: t('agents'),
      to: '/admin/agents',
      icon: <BusinessSettingsIcon />,
    });
    adminChild.push({
      name: t('clients'),
      to: '/admin/clients',
      icon: <ProfileSettingsIcon />,
    });
    adminChild.push({
      name: t('sendPush'),
      to: '/admin/notifications/push',
      icon: (
        <EmojiIcon>
          <Text fontSize={18}>📲</Text>
        </EmojiIcon>
      ),
    });
  }

  return (
    <VStack alignItems="flex-start" gap="20px" w="full">
      {links.map((link, index) => (
        <Box
          key={index}
          as={NavLink}
          role="group"
          to={link.to}
          userSelect="none"
          w="full">
          {({ isActive }) => (
            <>
              <Tooltip
                hasArrow
                isDisabled={toggleSidebar}
                label={link.name}
                placement="right">
                <Box>
                  <ListItem
                    count={link.count ?? null}
                    hasAttention={link.isNeedAttention}
                    icon={link.icon}
                    isActive={isActive}
                    name={link.name}
                  />
                </Box>
              </Tooltip>
              {link.to === '/settings' && isActive && (
                <VStack mt={4} spacing={3}>
                  {settingsChild.map((child, childIndex) => (
                    <Box
                      key={childIndex}
                      as={NavLink}
                      to={child.to}
                      userSelect="none"
                      w="full">
                      {({ isActive: isActiveChild }) => (
                        <ListItem
                          isChild
                          count={child.count ?? null}
                          customComponent={child?.custom}
                          hasAttention={child.isNeedAttention}
                          icon={child.icon}
                          isActive={isActiveChild}
                          name={child.name}
                        />
                      )}
                    </Box>
                  ))}
                </VStack>
              )}
              {link.to === '/admin' && isActive && (
                <VStack mt={4} spacing={3}>
                  {adminChild?.map((child, childIndex) => (
                    <Box
                      key={childIndex}
                      as={NavLink}
                      role="group"
                      to={child.to}
                      userSelect="none"
                      w="full">
                      {({ isActive: isActiveChild }) => (
                        <ListItem
                          isChild
                          count={child.count ?? null}
                          icon={child.icon}
                          isActive={isActiveChild}
                          name={child.name}
                        />
                      )}
                    </Box>
                  ))}
                </VStack>
              )}
            </>
          )}
        </Box>
      ))}
    </VStack>
  );
};
