import { signOut } from 'aws-amplify/auth';

import { db } from '@app/db';

export const useSignOut = (): {
  signOut: () => void;
} => {
  const logOut = async () => {
    try {
      db.table('chats').clear();
      await signOut();
    } catch (error) {
      console.error(error);
    }
    window.location.replace('/');
  };

  return {
    signOut: logOut,
  };
};
