import {
  Button,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
  HStack,
} from '@chakra-ui/react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router';

import { ReactComponent as ViewIcon } from '@app/icons/offer-view-icon.svg';
import { ReactComponent as DeleteIcon } from '@app/icons/delete-icon.svg';
import { ReactComponent as EditIcon } from '@app/icons/edit-icon.svg';
import { ReactComponent as ContextMenuIcon } from '@app/icons/context-menu-icon.svg';
import { OfferStatus } from '@app/api/gql/generated-types';
import { ActionModal } from '@app/views/Offers/components/ActionModal';
import { OfferFragment } from '@app/api/schemas/offer.mongo.generated';
import { useOfferStatus } from '@app/views/Offers/hooks/useOfferStatus';

interface OrderOfferInformationMenuProps {
  offer?: Partial<OfferFragment>;
}

export const OrderOfferInformationMenu: FC<OrderOfferInformationMenuProps> = ({
  offer,
}) => {
  const { t } = useTranslation('forms');
  const { changeStatus, setOffer, showOffer, hideOffer } = useOfferStatus();
  const confirmDisclosure = useDisclosure();
  const archiveOfferConfirm = useDisclosure();

  const handleChange = () => {
    setOffer(offer);
    confirmDisclosure.onOpen();
  };

  const handleSubmit = async () => {
    confirmDisclosure.onClose();
    if (offer.isPublic) {
      await hideOffer();
    } else {
      await showOffer();
    }
  };

  const onDelete = () => {
    setOffer(offer);
    archiveOfferConfirm.onOpen();
  };

  const removeOffer = async () => {
    await changeStatus(OfferStatus.ARCHIVE);
    archiveOfferConfirm.onClose();
  };

  return (
    <>
      <Menu>
        <MenuButton
          as={Button}
          bg="transparent"
          borderRadius="10px"
          color="grey.300"
          height="37px"
          padding={0}
          variant="unstyled"
          width="37px">
          <ContextMenuIcon
            color="grey.300"
            fill="grey.300 !important"
            height="24px"
            stroke="grey.300"
            style={{ margin: '0 auto', transform: 'rotate(90deg)' }}
            width="24px"
          />
        </MenuButton>
        <MenuList
          border="0 none"
          boxShadow="0 4px 5px rgba(0,0,0,0.15)"
          width="160px">
          <MenuItem as={Link} to={`/offers/edit/${offer?._id}`}>
            <HStack color="grey.300">
              <EditIcon width="25px" />
              <Text color="grey.300" fontSize="14px" fontWeight="500">
                {t('EditOffer')}
              </Text>
            </HStack>
          </MenuItem>
          {offer?.status !== OfferStatus.ARCHIVE && (
            <MenuItem onClick={onDelete}>
              <HStack color="grey.300">
                <DeleteIcon width="25px" />
                <Text color="grey.300" fontSize="14px" fontWeight="500">
                  {t('ArchiveOffer')}
                </Text>
              </HStack>
            </MenuItem>
          )}
          <MenuItem onClick={handleChange}>
            <HStack color="grey.300">
              <ViewIcon width="25px" />
              <Text color="grey.300" fontSize="14px" fontWeight="500">
                {offer?.isPublic ? t('MakePrivate') : t('MakePublic')}
              </Text>
            </HStack>
          </MenuItem>
        </MenuList>
      </Menu>

      {archiveOfferConfirm.isOpen && (
        <ActionModal
          {...archiveOfferConfirm}
          offer={offer}
          type="Achive"
          onSubmit={removeOffer}
        />
      )}

      {confirmDisclosure.isOpen && (
        <ActionModal
          {...confirmDisclosure}
          offer={offer}
          type="ShowHide"
          onSubmit={handleSubmit}
        />
      )}
    </>
  );
};
