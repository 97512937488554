import React, { useEffect, useMemo } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router';

import {
  accountInfoRoute,
  adminRoutes,
  authRoutes,
  businessInfoRoute,
  guestRoutes,
  mainRoutes,
  signUpRouteAgents,
  systemRoutes,
} from '@app/routes';
import { useBootstrap } from '@app/hooks/useBootstrap';
import CurrentAccountProvider from '@app/providers/CurrentAccountProvider';
import { amplitude } from '@app/amplitude';
import { useWhoAmIQuery } from '@app/api/schemas/account.amazon.generated';

import { GlobalSkeleton } from '../GlobalSkeleton';

export const Navigation = () => {
  const { registered, bootstrapped, user, userAttributes } = useBootstrap();

  const { data: { whoAmI: account } = {}, loading: isLoading } = useWhoAmIQuery(
    {
      skip: !user?.userId,
      context: {
        clientName: 'amazon',
      },
    },
  );

  const business = account?.business;

  const isAdmin = userAttributes?.['custom:role'] === 'admin';
  const isManager = userAttributes?.['custom:role'] === 'manager';

  const protectedMainRoutes = useMemo(
    () => ({
      ...(!!registered &&
        !!account &&
        !!business &&
        (isManager || isAdmin) &&
        mainRoutes),
    }),
    [account, business, isAdmin, isManager, registered],
  );

  const admin = {
    ...(isAdmin && adminRoutes),
  };

  const accountRoute = {
    ...(!!registered && !account?.phone && accountInfoRoute),
  };

  const businessRoute = {
    ...(!!registered && account && !business && businessInfoRoute),
  };

  const restrictedRoutes = {
    ...(!!registered && !isManager && !isAdmin && guestRoutes),
  };

  const protectedAuthRoutes = {
    ...(!registered && authRoutes),
  };
  const authAgentRoute = {
    ...(!registered && signUpRouteAgents),
  };

  const router = createBrowserRouter([
    protectedMainRoutes,
    authAgentRoute,
    restrictedRoutes,
    accountRoute,
    businessRoute,
    protectedAuthRoutes,
    admin,
    systemRoutes,
  ]);

  useEffect(() => {
    if (!!account?._id) {
      amplitude.setUserId(account._id);
    }
  }, [account?._id]);

  if (!bootstrapped || isLoading) {
    return <GlobalSkeleton />;
  }

  return (
    <CurrentAccountProvider
      account={account}
      user={user}
      userAttributes={userAttributes}>
      <RouterProvider router={router} />
    </CurrentAccountProvider>
  );
};
