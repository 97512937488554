import { FormControl, HStack, Radio, RadioGroup, Text } from '@chakra-ui/react';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Offer } from '@app/api/gql/generated-types';
import Label from '@app/components/FormElements/Label';
import { CommisionType } from '@app/api/types';

export const OfferCommisionType = () => {
  const { t } = useTranslation('forms');
  const { control, setValue } = useFormContext<Offer>();
  const { field: { value } = {} } = useController<Offer>({
    name: 'isCommissionIncluded',
    control,
  });
  const handleChange = (val: CommisionType) => {
    const bool = val === CommisionType.HAS_COMMISION ? true : false;

    setValue('isCommissionIncluded', bool);
  };

  const defaultValue = !!value
    ? CommisionType.HAS_COMMISION
    : CommisionType.NO_COMMISION;

  return (
    <FormControl>
      <Label color="grey.300" fontSize={14} mx={0}>
        {t('commissionBlock.description')}
      </Label>

      <RadioGroup
        mb="15px"
        value={defaultValue}
        variant="primary"
        onChange={handleChange}>
        <HStack spacing={5}>
          <Radio value={CommisionType.HAS_COMMISION}>
            <HStack>
              <Text color="grey.250">
                {t('commissionBlock.values.included')}
              </Text>
            </HStack>
          </Radio>
          <Radio value={CommisionType.NO_COMMISION}>
            <HStack>
              <Text color="grey.250">
                {t('commissionBlock.values.excluded')}
              </Text>
            </HStack>
          </Radio>
        </HStack>
      </RadioGroup>
    </FormControl>
  );
};
