import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';

import { createAmazonPaginationVariables } from '@app/api/apollo-pagination';
import { useApolloLoadingStatus } from '@app/hooks/useApolloLoadingStatus';
import { useOrdersQuery } from '@app/api/schemas/order.amazon.generated';
import useCurrentAccount from '@app/hooks/useCurrentAccount';
import { FilterOption } from '@app/api/gql/generated-types-amazon';
import { useAccountIsAdmin } from '@app/hooks/useAccountIsAdmin';

import { initialValues } from '../components/Filter/initialValues';

const sort = 'createdAt__desc';

export const useOrdersData = ({
  pollInterval = null,
}: {
  pollInterval?: number;
}) => {
  const { account } = useCurrentAccount();
  const { isAdmin } = useAccountIsAdmin();
  const form = useForm({
    defaultValues: initialValues,
    mode: 'all',
  });

  const [price, dates, status, orderNumber] = form.watch([
    'price',
    'dates',
    'status',
    'orderNumber',
  ]);

  const onReset = () => form.reset();

  const filter = {
    items: [
      ...(!isAdmin
        ? [
            {
              fieldName: 'offerOwner',
              value: account?._id,
              filterOption: FilterOption.Equals,
            },
          ]
        : []),
    ],
  };

  if (price?.from || price?.to) {
    filter.items.push({
      fieldName: 'offer.price.value',
      value: `${!!price?.from ? price.from * 100 : 0}:${
        !!price?.to ? price.to * 100 : 999999
      }`,
      filterOption: FilterOption.Between,
    });
  }
  if (orderNumber?.length === 6) {
    filter.items.push({
      fieldName: 'orderNumber',
      value: orderNumber.toString(),
      filterOption: FilterOption.Equals,
    });
  }

  if (!!status?.length) {
    filter.items.push({
      fieldName: 'status',
      value: status?.map((v) => v.value)?.join(','),
      filterOption: FilterOption.In,
    });
  }

  if (dates?.from && dates?.to) {
    filter.items.push({
      fieldName: 'startAt',
      value: `${new Date(dates.from).toDateString()}:${new Date(
        dates.to,
      ).toDateString()}`,
      filterOption: FilterOption.Between,
    });
  }

  const {
    data: { orders: findOrders } = {},
    networkStatus,
    fetchMore,
    refetch,
  } = useOrdersQuery({
    variables: {
      pagination: {
        limit: 20,
        sort,
      },
      filter,
    },
    context: {
      clientName: 'amazon',
    },
    pollInterval,
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });
  const orders = useMemo(() => findOrders?.items ?? [], [findOrders?.items]);

  const { isLoading, isLoadingData, isReady } =
    useApolloLoadingStatus(networkStatus);
  const fetchNextPage = async () => {
    try {
      if (findOrders?.hasNext && !isLoadingData) {
        await fetchMore(
          createAmazonPaginationVariables(findOrders, 20, filter, sort),
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  return {
    orders,
    form,
    onReset,
    fetchNextPage,
    hasNextPage: findOrders?.hasNext,
    total: findOrders?.total,
    isLoading,
    isLoadingData,
    isReady,
    refetch,
    statusCounts: [],
    isFiltered: !isEmpty(filter),
  };
};
