import {
  Button,
  HStack,
  Icon,
  Menu,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import React, { FC, useCallback } from 'react';
import { DropzoneOptions, FileRejection, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { useErrorNotification } from '@app/hooks/useErrorNotification';
import { ReactComponent as Upload } from '@app/icons/image-plus-svgrepo-com.svg';
import { ReactComponent as Add } from '@app/icons/new-add-icon.svg';
import { ReactComponent as PlusIcon } from '@app/icons/plus-icon-input.svg';
import { useAddOfferModalContext } from '@app/providers/AddOfferModalProvider';

import { EmojiButton } from './EmojiButton';

interface ActionButtonsProps {
  onChangeImage: (file: File) => void;
  onEmojiAdd: (val: string) => void;
  isSmall?: boolean;
  chatId?: string;
  isShowSendOffer?: boolean;
}

export const ActionButtons: FC<ActionButtonsProps> = ({
  onChangeImage,
  onEmojiAdd,
  isSmall = false,
  chatId,
  isShowSendOffer = false,
}) => {
  const { t } = useTranslation('forms');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { handleOpen } = useAddOfferModalContext();

  const errorNotification = useErrorNotification();
  const onDrop = (uploadedFiles: File[], rejectedFiles: FileRejection[]) => {
    if (rejectedFiles.length > 0) {
      const rejectedFilesString = rejectedFiles
        ?.map((file) => file.file.name)
        ?.join(', ');

      errorNotification({
        message: `Error uploading files: ${rejectedFilesString}`,
      });
    }
    onChangeImage(uploadedFiles[0]);
  };
  const dropzoneOptions: DropzoneOptions = {
    onDrop,
    maxFiles: 1,
    maxSize: 1024 * 1024 * 5,
    noClick: true,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg'],
      'image/jpg': ['.jpg'],
    },
  };

  const { open, getInputProps } = useDropzone({
    ...dropzoneOptions,
  });

  const handleOpenUpload = useCallback(() => {
    open();
  }, []);

  const handleOpenModal = useCallback(async () => {
    try {
      await handleOpen({ chatId });
    } catch (error) {}
  }, []);

  const handleChangeEmoji = useCallback((val) => {
    onEmojiAdd(val?.emoji);
  }, []);

  return (
    <>
      <HStack mb={1} spacing={0}>
        {!isSmall ? (
          <>
            <Tooltip label={t('messenger.uploadImage')} placement="top">
              <Button
                borderRadius="50%"
                color="primary.green"
                h={8}
                minW={8}
                p={0}
                transition="0.3s 0.1s"
                variant="icon"
                w={8}
                onClick={handleOpenUpload}>
                <Icon as={Upload} color="primary.green" h={6} w={6} />
                <input {...getInputProps()} />
              </Button>
            </Tooltip>
            {isShowSendOffer && (
              <Tooltip label={t('messenger.createOffer')} placement="top">
                <Button
                  borderRadius="50%"
                  color="primary.green"
                  h={8}
                  minW={8}
                  p={0}
                  transition="0.3s 0.1s"
                  variant="icon"
                  w={8}
                  onClick={handleOpenModal}>
                  <Add height="20px" width="20px" />
                </Button>
              </Tooltip>
            )}
          </>
        ) : (
          <Button
            borderRadius="50%"
            color="primary.green"
            h={8}
            p={0}
            transition="0.3s 0.1s"
            variant="icon"
            w={8}
            onClick={onOpen}>
            <HStack alignItems="center" justifyContent="center" w="full">
              <PlusIcon height="14px" width="14px" />
            </HStack>
          </Button>
        )}
        <EmojiButton handleChange={handleChangeEmoji} />
      </HStack>
      <Menu isOpen={isOpen} onClose={onClose}>
        <MenuList border="0 none" boxShadow="0 4px 5px rgba(0,0,0,0.15)">
          <MenuItem>
            <Button variant="unstyled" onClick={open}>
              <HStack>
                <Icon as={Upload} color="primary.green" h={6} w={8} />
                <Text color="grey.300" fontSize="14px" fontWeight="600">
                  {t('messenger.uploadImage')}
                </Text>
              </HStack>
            </Button>
          </MenuItem>
          {isShowSendOffer && (
            <MenuItem>
              <Button variant="unstyled" w="full" onClick={handleOpenModal}>
                <HStack color="grey.300">
                  <Icon as={Add} color="primary.green" h="20px" w={8} />
                  <Text color="grey.300" fontSize="14px" fontWeight="600">
                    {t('messenger.createOffer')}
                  </Text>
                </HStack>
              </Button>
            </MenuItem>
          )}
        </MenuList>
      </Menu>
    </>
  );
};
