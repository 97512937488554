import { Button, Text } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router';

export const SignInLink = () => {
  const { t } = useTranslation(['forms']);
  return (
    <Text color="grey.250" fontSize="16px" fontWeight="400">
      {t('haveAcc')}
      <Button
        as={NavLink}
        fontSize="16px"
        fontWeight="500"
        ms="5px"
        to="/"
        variant="link">
        {t('signIn')}
      </Button>
    </Text>
  );
};
