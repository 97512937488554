import { FormControl, HStack, Radio, RadioGroup, Text } from '@chakra-ui/react';
import React from 'react';
import { useController, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Offer, OfferType } from '@app/api/gql/generated-types';
import Label from '@app/components/FormElements/Label';

export const OfferTypeChange = () => {
  const { control, setValue } = useFormContext<Offer>();
  const { requestId, offerAddition } = useWatch<Offer>([
    'requestId',
    'offerAddition',
  ]);
  const { t } = useTranslation(['forms', 'addOffer']);
  const { field: { value } = {} } = useController<Offer>({
    name: 'type',
    control,
  });

  const handleChange = (val: OfferType) => {
    setValue('type', val);
    if (val === OfferType.PERSON) {
      for (let index = 0; index < offerAddition.length; index++) {
        setValue(`offerAddition.${index}.adults`, 1);
        setValue(`offerAddition.${index}.children`, undefined);
      }
      return;
    }
    for (let index = 0; index < offerAddition.length; index++) {
      setValue(`offerAddition.${index}.adults`, 1);
      setValue(`offerAddition.${index}.children`, '');
    }
  };

  return (
    <FormControl>
      <Label color="grey.300" fontSize={14} ms={0}>
        {t('typeLabel')}
      </Label>
      <RadioGroup
        isDisabled={!!requestId}
        mb="15px"
        value={value}
        variant="primary"
        onChange={handleChange}>
        <HStack spacing={5}>
          <Radio value={OfferType.PERSON}>
            <HStack>
              <Text color="grey.250">{t('offerTypePerson')}</Text>
            </HStack>
          </Radio>
          <Radio value={OfferType.TOUR}>
            <HStack>
              <Text color="grey.250">{t('offerTypeTour')}</Text>
            </HStack>
          </Radio>
        </HStack>
      </RadioGroup>
    </FormControl>
  );
};
