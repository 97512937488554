import {
  Badge,
  Box,
  Flex,
  HStack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import React, { FC, ReactNode, useContext } from 'react';

import { SidebarContext } from '@app/contexts/SidebarContext';

interface ListItemProps {
  isActive: boolean;
  icon?: ReactNode;
  name: string;
  count?: number;
  hasAttention?: boolean;
  isChild?: boolean;
  customComponent?: ReactNode;
}

export const ListItem: FC<ListItemProps> = ({
  isActive,
  icon,
  name,
  count,
  hasAttention = false,
  isChild = false,
  customComponent,
}) => {
  const inactiveColor = useColorModeValue('grey.300', 'secondaryGray.600');
  const activeIcon = useColorModeValue('primary.green', 'white');
  const { toggleSidebar } = useContext(SidebarContext);

  return (
    <HStack
      align="center"
      justifyContent="space-between"
      minH="32px"
      pl={isChild && toggleSidebar ? '45px' : 0}>
      <HStack position="relative" spacing="15px">
        <Flex data-group alignItems="center" justifyContent="start">
          {(!isChild || (isChild && !toggleSidebar)) && (
            <Box
              color={isActive ? activeIcon : inactiveColor}
              mx="10px"
              transition="0.2s">
              {icon}
            </Box>
          )}
          {toggleSidebar && (
            <Text
              _hover={{
                color: 'black.100',
              }}
              transition="all 0.15s"
              variant={isActive ? 'secondaryDark' : 'newMain'}>
              {name}
            </Text>
          )}
        </Flex>
        {!!count && !toggleSidebar && (
          <Badge
            alignItems="center"
            backgroundColor="red"
            color="white"
            display="flex"
            h={5}
            justifyContent="center"
            p="2px"
            pl="4px"
            position="absolute"
            pr="4px"
            right="-6px"
            top="-7px"
            w={5}>
            {count > 9 ? '9+' : count}
          </Badge>
        )}

        {hasAttention && !toggleSidebar && (
          <Box
            backgroundColor="red"
            borderRadius="full"
            h={2}
            position="absolute"
            right="-6px"
            top="-7px"
            w={2}
          />
        )}
      </HStack>
      {!!count && toggleSidebar && (
        <Box
          _groupHover={{
            opacity: 0.8,
          }}
          backgroundColor="mainGray.200"
          borderRadius={10}
          minW={10}
          p="5px"
          transition="all 0.15s">
          <Text
            color="mainGray.100"
            fontSize={14}
            fontWeight={600}
            textAlign="center">
            {count}
          </Text>
        </Box>
      )}
      {customComponent && !!toggleSidebar && customComponent}
      {hasAttention && toggleSidebar && (
        <Box flex={1}>
          <Box
            backgroundColor="red"
            borderRadius="full"
            h={2}
            left={-0.5}
            position="relative"
            top={-1.5}
            w={2}
          />
        </Box>
      )}
    </HStack>
  );
};
