import { Box, HStack, Text } from '@chakra-ui/react';
import React, { FC, PropsWithChildren } from 'react';
import { Link } from 'react-router';

import { ReactComponent as Arrow } from '@app/icons/arrow-left-icon.svg';

import { SearchBar } from './SearchBar';

interface LayoutProps extends PropsWithChildren {
  title: string;
  subtitle?: string;
  isShowBack?: boolean;
  onSetSearch: (val: string) => void;
}

export const Layout: FC<LayoutProps> = ({
  title,
  subtitle,
  isShowBack = false,
  onSetSearch,
  children,
}) => {
  return (
    <Box position="relative">
      <Box mx={{ base: '14px', md: '28px' }} py={{ base: '10px', md: '50px' }}>
        <HStack alignItems="center" color="grey.300" maxW="600px" mx="auto">
          {isShowBack && (
            <Box as={Link} cursor="pointer" justifySelf="flex-start" to="/help">
              <Arrow />
            </Box>
          )}
          <Text
            color="brand.900"
            flex={1}
            fontSize={{ base: 26, md: 32 }}
            fontWeight={700}
            mr="25px"
            textAlign="center">
            {title}
          </Text>
        </HStack>
        <Box maxW="600px" mt="50px" mx="auto" textAlign="center">
          <Text
            color="grey.300"
            fontSize={{ base: 16, md: 20 }}
            fontWeight={500}
            lineHeight="42px">
            {subtitle}
          </Text>
          <SearchBar onSetSearch={onSetSearch} />
        </Box>
        <Box minH="300px">{children}</Box>
      </Box>
    </Box>
  );
};
