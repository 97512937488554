import { Box, useDisclosure } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { Outlet, matchRoutes, useLocation } from 'react-router';

import { SidebarMobile } from '@app/components/mobile/SidebarMobile';
import Sidebar from '@app/components/Sidebar';
import { SidebarContext } from '@app/contexts/SidebarContext';
import AddOfferModalProvider from '@app/providers/AddOfferModalProvider';
import ChatWidgetProvider from '@app/providers/ChatWidgetProvider';
import NotificationProvider from '@app/providers/NotificationProvider';
import { ChatWidget } from '@app/widgets/Chats';
import { HelpWidget } from '@app/widgets/Help';

export const isGreetingModalOpenedKey = 'isGreetingModalOpened';

const chatRoutes = [{ path: '/chat' }, { path: '/chat/:id' }];

export const MainLayout = () => {
  const location = useLocation();
  const matchChatRoutes = matchRoutes(chatRoutes, location);

  const {
    onToggle,
    isOpen: toggleSidebar,
    onClose,
    onOpen,
  } = useDisclosure({ defaultIsOpen: true });

  const mainPageWidth = !toggleSidebar ? '74px' : '350px';

  const isChatPage = useMemo(
    () => !!matchChatRoutes?.length,
    [matchChatRoutes?.length],
  );

  return (
    <Box overflowX="hidden" position="relative">
      <NotificationProvider>
        <SidebarContext.Provider
          value={{
            toggleSidebar,
            onToggleSideBar: onToggle,
            onCloseSidebar: onClose,
            onOpenSidebar: onOpen,
          }}>
          <Box display={{ base: 'none', md: 'block' }}>
            <Sidebar />
          </Box>
          <ChatWidgetProvider>
            <AddOfferModalProvider>
              <Box
                float="right"
                height="100dvh"
                maxHeight="100%"
                maxWidth={{
                  base: '100%',
                  md: `calc( 100% - ${mainPageWidth} )`,
                }}
                minHeight="100dvh"
                pb={{ base: '50px', md: 0 }}
                position="relative"
                transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
                transitionDuration=".2s, .2s, .35s"
                transitionProperty="top, bottom, width"
                transitionTimingFunction="linear, linear, ease"
                w={{ base: '100%', xl: `calc( 100% - ${mainPageWidth} )` }}>
                <Box minH="100dvh" mx="auto" p={{ base: 0, md: '0px 22px' }}>
                  <HelpWidget />
                  <Outlet />
                  <SidebarMobile />
                </Box>
                {!isChatPage && <ChatWidget />}
              </Box>
            </AddOfferModalProvider>
          </ChatWidgetProvider>
        </SidebarContext.Provider>
      </NotificationProvider>
    </Box>
  );
};
