import { HStack, IconButton } from '@chakra-ui/react';
import addDays from 'date-fns/addDays';
import React, { FC, useMemo } from 'react';

import { OfferAdditionFragment } from '@app/api/schemas/offer.amazon.generated';
import { DateFormatter } from '@app/components/DateFormatter';
import { PriceField } from '@app/components/PriceField';
import { useCalculateCommision } from '@app/hooks/useCalculateCommision';
import { ReactComponent as RemoveIcon } from '@app/icons/delete-icon.svg';
import { ReactComponent as EditIcon } from '@app/icons/offer-pen-icon.svg';

interface OfferPriceLineProps {
  hasCommision: boolean;
  isFirst?: boolean;
  onEdit: () => void;
  onRemove: () => void;
  item: OfferAdditionFragment;
}

export const OfferPriceLine: FC<OfferPriceLineProps> = ({
  hasCommision,
  isFirst = false,
  item,
  onEdit,
  onRemove,
}) => {
  const { price, startDate, days, adults, children } = item;

  const { fullPrice } = useCalculateCommision({
    price,
    commission: hasCommision,
  });

  const endDate = useMemo(() => {
    if (!startDate && !days) {
      return;
    }
    return addDays(new Date(startDate), Number(days));
  }, [days, startDate]);

  return (
    <HStack color="primary.green" fontSize={14} py={1} spacing={3}>
      <HStack _hover={{ opacity: 0.85 }} cursor="pointer" onClick={onEdit}>
        {!!startDate && !!endDate && (
          <>
            <DateFormatter date={new Date(startDate)} /> -{' '}
            <DateFormatter date={endDate} />;{' '}
          </>
        )}
        {!!fullPrice && (
          <>
            <PriceField value={fullPrice} />;{' '}
          </>
        )}
        ;
        {!!adults && (
          <>
            {adults}

            {!!children && <>+{children}</>}
          </>
        )}
      </HStack>
      <HStack alignItems="center" spacing={1}>
        <IconButton
          aria-label="Edit offer"
          color="grey.250"
          size="xs"
          variant="icon"
          onClick={onEdit}>
          <EditIcon height={18} width={18} />
        </IconButton>
        {!isFirst && (
          <IconButton
            aria-label="Remove offer"
            color="grey.250"
            size="xs"
            variant="icon"
            onClick={onRemove}>
            <RemoveIcon height={22} width={22} />
          </IconButton>
        )}
      </HStack>
    </HStack>
  );
};
