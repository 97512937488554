import { FieldPolicy, Reference } from '@apollo/client';

interface ListResult<T> {
  items: T[];
  hasNextPage?: boolean | null;
  nextId?: string | null;
  nextCreatedAt?: string | null;
  total?: number | null;
}

interface MergeArgs {
  args: {
    input: {
      limit: number;
      nextId?: string | null;
      nextCreatedAt?: string | null;
    };
  };
}

export function cursorPagination<T = Reference>(
  keyArgs: FieldPolicy['keyArgs'] = false,
): FieldPolicy<Readonly<ListResult<T>>, ListResult<T>> {
  return {
    keyArgs,
    merge(existing = { items: [] }, incoming, { args }: MergeArgs) {
      if (!args?.input?.nextId) {
        return incoming;
      }

      if (incoming?.items?.[0]?.__ref === existing?.items?.[0]?.__ref) {
        return incoming;
      }

      const merged = {
        ...incoming,
        items: [...existing?.items, ...incoming?.items],
      };

      if (!!args?.input?.nextId) {
        return merged;
      }

      return {
        ...merged,
        items: [...incoming?.items, ...existing?.items],
      };
    },
  };
}
export function cursorAmazonPagination<T = Reference>(
  keyArgs: FieldPolicy['keyArgs'] = false,
): FieldPolicy<Readonly<ListResult<T>>, ListResult<T>> {
  return {
    keyArgs,
    merge(existing, incoming, { args }: MergeArgs) {
      if (!args?.pagination?.nextId) {
        return incoming;
      }

      if (incoming?.items?.[0]?.__ref === existing?.items?.[0]?.__ref) {
        return incoming;
      }

      const merged = {
        ...incoming,
        items: [...existing?.items, ...incoming?.items],
      };

      if (!!args?.pagination?.nextId) {
        return merged;
      }

      return {
        ...merged,
        items: [...incoming?.items, ...existing?.items],
      };
    },
  };
}

export function createPaginationVariables<T extends ListResult<unknown>>(
  data: T,
  limit = 25,
  filter = null,
  sort = null,
) {
  return {
    variables: {
      input: {
        ...(filter && { filter }),
        ...(sort && { sort }),
        limit,
        nextId: data?.nextId,
        nextCreatedAt: data?.nextCreatedAt,
      },
    },
  };
}
export function createAmazonPaginationVariables<T extends ListResult<unknown>>(
  data: T,
  limit = 25,
  filter = null,
  sort = null,
) {
  return {
    variables: {
      ...(filter && { filter }),
      pagination: {
        ...(sort && { sort }),
        limit,
        nextId: data?.nextId,
        nextCreatedAt: data?.nextCreatedAt,
      },
    },
  };
}
