import { Button } from '@chakra-ui/react';
import React, { useContext } from 'react';
import { useNavigate, useSearchParams } from 'react-router';

import { ReactComponent as Add } from '@app/icons/new-add-icon.svg';
import { SidebarContext } from '@app/contexts/SidebarContext';

export const AddOfferButton = () => {
  const { toggleSidebar } = useContext(SidebarContext);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const onPageRedirect = () => {
    const param = searchParams.get('requestId');

    if (param) {
      searchParams.delete('requestId');
      setSearchParams(searchParams);
    }
    navigate('/offers/create');
  };

  return (
    <Button
      _focus={{
        background: toggleSidebar ? 'primary.green' : 'transparent',
        color: toggleSidebar ? 'white' : 'primary.green',
      }}
      _hover={{
        background: toggleSidebar ? 'primary.green' : 'transparent',
        color: toggleSidebar ? 'white' : 'primary.green',
      }}
      background={toggleSidebar ? 'primary.green' : 'transparent'}
      borderRadius="50%"
      color={toggleSidebar ? 'white' : 'primary.green'}
      fontSize="16px"
      height="40px"
      justifyContent="flex-start"
      px="10px"
      transition="0.3s 0.1s"
      variant="primary"
      w="auto"
      onClick={onPageRedirect}>
      <Add height="20px" width="20px" />
    </Button>
  );
};
