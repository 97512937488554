import { useCallback, useState } from 'react';
import omit from 'lodash/omit';

import { useS3 } from '@app/api/hooks/useS3';
import { unsplash } from '@app/api/unsplash';
import { encodeImageToBlurhash } from '@app/utils/createBlurHash';

const imageServer = process.env.REACT_APP_AMAZON_IMAGE_SERVER;

export const useUploadFiles = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { offersClient, accountClient, docsClient } = useS3();
  const upload = useCallback(
    async (file: File) => {
      try {
        setIsLoading(true);

        const data = await offersClient.uploadFile(file, file.name);

        return omit({ ...data, hash: '' }, 'status');
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [offersClient],
  );
  const uploadToAccount = useCallback(
    async (file: File) => {
      try {
        setIsLoading(true);

        const data = await accountClient.uploadFile(file, file.name);

        return omit({ ...data, hash: '' }, 'status');
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [accountClient],
  );
  const uploadMultipleToAccount = useCallback(
    async (files: File[]) => {
      const images = [];
      try {
        setIsLoading(true);
        for await (const image of files) {
          const data = await accountClient.uploadFile(image, image.name);
          images.push(omit(data, 'status'));
        }
        return images?.map((image) => ({
          bucket: image?.bucket,
          key: image?.key,
          location: image?.location,
          hash: '',
        }));
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [accountClient],
  );
  const uploadMultiple = useCallback(
    async (files: File[]) => {
      const images = [];
      try {
        setIsLoading(true);
        for await (const image of files) {
          if (image instanceof File) {
            const data = await offersClient.uploadFile(image, image.name);
            // const hash = await encodeImageToBlurhash(
            //   `${imageServer}${data.key}?width=800`,
            // );

            images.push(omit(data, 'status'));
          } else if (image?.id) {
            await unsplash.photos.trackDownload({
              downloadLocation: image.links.download_location,
            });
            const blob = await fetch(image?.urls?.full)
              .then((r) => r.blob())
              .then(
                (blobFile) =>
                  new File([blobFile], image.slug, { type: 'image/jpg' }),
              );
            const data = await offersClient.uploadFile(blob, blob.name);
            // const hash = await encodeImageToBlurhash(
            //   `${imageServer}${data.key}?width=800`,
            // );

            images.push(omit(data, 'status'));
          } else {
            // const hash = await encodeImageToBlurhash(
            //   `${imageServer}${image.key}?width=800`,
            // );

            images.push(image);
          }
        }
        return images?.map((image) => ({
          bucket: image?.bucket,
          key: image?.key,
          location: image?.location,
          hash: image?.hash,
        }));
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [offersClient],
  );
  const uploadDocs = useCallback(
    async (files: File[]) => {
      const docs = [];
      try {
        setIsLoading(true);
        for await (const file of files) {
          const data = await docsClient.uploadFile(file, file.name);
          docs.push(omit(data, 'status'));
        }
        return docs;
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [docsClient],
  );

  return {
    upload,
    uploadMultiple,
    uploadToAccount,
    uploadDocs,
    uploadMultipleToAccount,
    isLoading,
  };
};
