import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  HStack,
  SlideFade,
  Text,
} from '@chakra-ui/react';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router';

import { contentfulClient } from '@app/api/contentful';
import { getLocale } from '@app/components/FormatNumber/utils';
import { useMobile } from '@app/hooks/useMobile';

import { Layout } from './components/Layout';
import { TextRenderer } from './components/TextRenderer';

interface HelpCategoryProps {
  categoriesId: string;
  onSetSearch: (val: string) => void;
  activeNode?: string;
}

export const HelpCategory: FC<HelpCategoryProps> = ({
  categoriesId,
  onSetSearch,
  activeNode,
}) => {
  useMobile();
  const { i18n, t } = useTranslation('forms');

  const [articles, setArticles] = useState([]);
  const [category, setCategory] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const defultIndex = useMemo(() => {
    if (!activeNode) {
      return null;
    }

    return articles?.findIndex((item) => item?.sys?.id === activeNode);
  }, [activeNode, articles]);

  const getArticles = useCallback(async () => {
    setIsLoading(true);
    try {
      const { items } = await contentfulClient.getEntries({
        limit: 100,
        locale: getLocale(i18n.resolvedLanguage),
        'fields.category.sys.id': categoriesId,
        content_type: 'articlesFaqAgentsApp',
        order: 'fields.order',
      });
      setArticles(items);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [i18n.resolvedLanguage, categoriesId]);

  const getCategory = useCallback(async () => {
    setIsLoading(true);
    try {
      const entry = await contentfulClient.getEntry(categoriesId, {
        locale: getLocale(i18n.resolvedLanguage),
      });
      setCategory(entry);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [i18n.resolvedLanguage, categoriesId]);

  useEffect(() => {
    if (categoriesId) {
      void getCategory();
      void getArticles();
    }
    return () => {
      setCategory();
      setArticles([]);
    };
  }, [i18n.resolvedLanguage, categoriesId]);

  if (isLoading) {
    return null;
  }

  return (
    <Layout
      subtitle={!categoriesId && t('faq.subtitle')}
      title={
        !!categoriesId && !!category?.fields?.name
          ? category?.fields?.name
          : t('faq.title')
      }
      onSetSearch={onSetSearch}>
      {!!category?.fields?.text && (
        <Box maxW="600px" mx="auto" my={5}>
          <TextRenderer>{category?.fields?.text}</TextRenderer>
        </Box>
      )}
      <SlideFade in={!!articles?.length} offsetY="20px">
        <Accordion
          allowToggle
          defaultIndex={[defultIndex]}
          maxW="600px"
          mt="50px"
          mx="auto">
          {articles?.map((item) => (
            <AccordionItem
              key={item?.sys?.id}
              border="0 none"
              borderBottom="1px solid"
              borderBottomColor="gray.200"
              mt="10px">
              <AccordionButton
                _hover={{
                  backgroundColor: 'transparent',
                }}
                as={Link}
                pb={4}
                px="0"
                to="?">
                <HStack flex={1} justifyContent="space-between">
                  <Text fontSize={20} fontWeight={500} lineHeight="28px">
                    {item?.fields?.question}
                  </Text>
                  <AccordionIcon color="grey.300" h="30px" w="30px" />
                </HStack>
              </AccordionButton>
              <AccordionPanel fontSize={16} lineHeight="24px" px={0}>
                <TextRenderer>{item?.fields?.answer}</TextRenderer>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </SlideFade>
    </Layout>
  );
};
