import { Button, HStack, useDisclosure } from '@chakra-ui/react';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useSuccessNotification } from '@app/hooks/useSuccessNotification';
import {
  OrderDocument,
  useCancelOrderMutation,
} from '@app/api/schemas/order.amazon.generated';

import { OrderCancelSubmitModal } from './OrderCancelSubmitModal';

interface OrderStatusDescriptionProps {
  id: string;
}

export const OrderCancelRequest: FC<OrderStatusDescriptionProps> = ({ id }) => {
  const cancelDisclosure = useDisclosure();
  const { t } = useTranslation(['tables', 'notifications']);
  const notification = useSuccessNotification();
  const [cancel, { loading }] = useCancelOrderMutation({
    context: {
      clientName: 'amazon',
    },
    refetchQueries: [
      {
        query: OrderDocument,
        variables: {
          id,
        },
        context: {
          clientName: 'amazon',
        },
      },
    ],
  });

  const handleCancelSubmit = useCallback(async () => {
    try {
      await cancel({
        variables: {
          id,
          reason: 'cancel by agent',
        },
      });
      cancelDisclosure.onClose();
      notification(t('booking.order.cancelled', { ns: 'notifications' }));
    } catch (error) {}
  }, [id]);

  return (
    <>
      <HStack
        alignItems="center"
        justifyContent="flex-end"
        px={5}
        py={2}
        w="full">
        <Button
          height={10}
          isDisabled={loading}
          isLoading={loading}
          size="sm"
          variant="danger"
          onClick={cancelDisclosure.onOpen}>
          {t('cancelBooking')}
        </Button>
      </HStack>
      <OrderCancelSubmitModal
        {...cancelDisclosure}
        isLoading={loading}
        onSubmit={handleCancelSubmit}
      />
    </>
  );
};
