import { Box, Button, HStack, VStack } from '@chakra-ui/react';
import React, { FC, useMemo, useState } from 'react';

import { OfferTabs } from '@app/api/types';
import { Tabs } from '@app/components/Sidebar/components/Tabs';
import { ReactComponent as OfferPreviewIcon } from '@app/icons/offer-preview-icon.svg';
import { ImageGallery } from '@app/views/AddOffer/components/AddOfferForm/InformationAndPreview/OfferPreview/ImageGallery';
import { Information } from '@app/views/Offers/components/Information/Information';
import { OrderInformation } from '@app/views/Orders/components/OrderInformation';
import { ReactComponent as CloseIcon } from '@app/icons/close-icon.svg';
import { getFile } from '@app/utils/getFile';
import { OrderAccountInfo } from '@app/views/Orders/components/OrderAccountInfo';
import OrderStatusButtons from '@app/views/Orders/components/OrderStatusButtons';
import { OrderStatus } from '@app/api/gql/generated-types';
import { OrderStatusDescription } from '@app/views/Orders/components/OrderStatusDescription';
import { OrderCancelRequest } from '@app/views/Orders/components/OrderCancelRequest';
import { useOrderQuery } from '@app/api/schemas/order.amazon.generated';
import {
  PaymentOrderStatus,
  PaymentStatus,
} from '@app/api/gql/generated-types-amazon';

import { SkeletonSidebar } from './SkeletonSidebar';

export interface OrderInfoContentProps {
  orderId?: string;
  onClose?: () => void;
}

export const OrderInfoContent: FC<OrderInfoContentProps> = ({
  orderId,
  onClose,
}) => {
  const [activeTab, setActiveTab] = useState<OfferTabs>(OfferTabs.OrderPreview);

  const { data: { order: orderItem } = {}, loading: isLoading } = useOrderQuery(
    {
      variables: {
        id: orderId,
      },
      context: {
        clientName: 'amazon',
      },
      skip: !orderId,
    },
  );

  const photoUris = useMemo(() => {
    return orderItem?.offer?.photo?.map((photo) => getFile(photo));
  }, [orderItem?.offer]);

  const isActiveOrder = useMemo(
    () => !(orderItem?.status === OrderStatus.CANCELLED),
    [orderItem?.status],
  );

  const isCancelRequest = useMemo(
    () => orderItem?.status === OrderStatus.CANCEL_REQUEST,
    [orderItem?.status],
  );

  const isPendingOrder = useMemo(
    () => orderItem?.status === OrderStatus.PENDING_AGENT,
    [orderItem?.status],
  );

  const isPendingPayment = useMemo(
    () => orderItem?.paymentStatus === PaymentStatus.PENDING,
    [orderItem?.paymentStatus],
  );

  if (isLoading) {
    return <SkeletonSidebar />;
  }

  return (
    <VStack alignItems="flex-start" h="full">
      <Box position="relative" w="100%">
        {!!photoUris?.length ? (
          <Box>
            <ImageGallery maxH="100px" maxW="100%" photoUris={photoUris} />
          </Box>
        ) : (
          <Box position="relative">
            <VStack
              backgroundColor="secondaryGray.100"
              justifyContent="center"
              minH="100px"
              px={[10, 24]}
              py={2}>
              <OfferPreviewIcon height="150px" opacity={0.2} width="100%" />
            </VStack>
          </Box>
        )}
      </Box>
      <HStack
        gap="13px"
        justifyContent="flex-end"
        position="absolute"
        right="18px"
        top="7px"
        zIndex={5}>
        <Button
          _hover={{
            background: 'transparent',
          }}
          background="transparent"
          borderRadius="0px"
          cursor={'pointer'}
          disabled={isLoading}
          h="30px"
          px="0"
          w="30px"
          onClick={onClose}>
          <CloseIcon color="white" fill="white" stroke="white" />
        </Button>
      </HStack>
      <Box
        borderBottom="1px solid"
        borderBottomColor="grey.150"
        px={5}
        w="full">
        <Tabs
          activeTab={activeTab}
          list={[OfferTabs.OrderPreview, OfferTabs.OfferPreview]}
          onChange={setActiveTab}
        />
      </Box>

      {activeTab === OfferTabs.OfferPreview && (
        <>
          <Box flex={1} overflowY="auto" px={5} w="full">
            <Information offer={orderItem?.originalOffer} />
          </Box>
        </>
      )}

      {activeTab === OfferTabs.OrderPreview && (
        <>
          <Box flex="1 auto" overflowY="auto" px={5} w="full">
            <OrderInformation order={orderItem} />
          </Box>
          <VStack bg="grey.100" py={5} spacing={2} w="full">
            <Box px={5} w="full">
              <OrderAccountInfo
                account={orderItem?.owner}
                orderId={orderItem?._id}
              />
            </Box>
            {isPendingOrder && <OrderStatusDescription />}
            {isPendingPayment && (
              <OrderStatusDescription textTransKey="orderStatusDescription.submitPayment" />
            )}
            {isCancelRequest && (
              <>
                <OrderStatusDescription
                  emoji="❗"
                  textTransKey="orderStatusDescription.cancelRequest"
                />
                <OrderCancelRequest id={orderItem?._id} />
              </>
            )}
            {((isActiveOrder && !isCancelRequest) || isPendingPayment) && (
              <Box px={5} w="full">
                <OrderStatusButtons
                  canSubmitPayment={isPendingPayment}
                  canUpdate={isPendingOrder}
                  id={orderItem?._id}
                  offerId={orderItem?.offer?._id}
                />
              </Box>
            )}
          </VStack>
        </>
      )}
    </VStack>
  );
};
