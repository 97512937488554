import { Box, Button, HStack, Heading, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactInputVerificationCode from 'react-input-verification-code';
import {
  Location as ReactLocation,
  useLocation,
  useNavigate,
} from 'react-router';
import { autoSignIn } from 'aws-amplify/auth';

import { useAuth } from '@app/hooks/useAuth';
import { useErrorNotification } from '@app/hooks/useErrorNotification';
import { useSuccessNotification } from '@app/hooks/useSuccessNotification';
import { amplitude } from '@app/amplitude';

export interface Location<State> extends Omit<ReactLocation, 'state'> {
  state: State;
}

export type CustomState = {
  username: string;
};

const Verify = styled('div')`
  .ReactInputVerificationCode {
    &__container {
      width: 100%;
    }
    &__item {
      font-size: 14px;
      background-color: #eaeaea;
      width: 50px;
      height: 50px;
      line-height: 50px;
      border-radius: 5px;
      box-shadow: 0 0 0 transparent;
      color: #787a89;
      font-size: 20px;
      font-weight: 500;
      &.is-active {
        box-shadow: 0 0 1px 1px #3d9897;
      }
    }
  }
`;

export const VerificationPage = () => {
  const errorToast = useErrorNotification();
  const successToast = useSuccessNotification();
  const { resendCode, handleSignUpConfirmation } = useAuth();
  const { t } = useTranslation('forms');
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location as Location<CustomState>;

  const username = state?.username;
  const [code, setCode] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = useCallback(async () => {
    await resendCode({ username });
  }, [username]);

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const { nextStep } = await handleSignUpConfirmation({
        username,
        confirmationCode: code,
      });
      amplitude.logEvent('SignUp Verified Email', { platform: 'web' });
      if (nextStep.signUpStep === 'COMPLETE_AUTO_SIGN_IN') {
        await autoSignIn();
        successToast(t('successVerify', { ns: 'validations' }));
        // navigate('/');
      }
      if (nextStep.signUpStep === 'DONE') {
        successToast(t('successVerify', { ns: 'validations' }));
        // navigate('/');
      }
    } catch (error) {
      errorToast(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!username) {
      navigate('/', { replace: true });
    }
  }, []);

  useEffect(() => {
    if (code?.length > 5) {
      void handleSubmit();
    }
  }, [code]);

  return (
    <Box>
      <Heading color="mainGray.500" fontSize="34px" fontWeight={600} mb="10px">
        {t('verificationLinkSent')}
      </Heading>
      <Heading color="mainGray.500" fontSize="24px" fontWeight={600} mb="15px">
        {t('verificationLinkSentMessage')}
      </Heading>
      <Text color="grey.250" fontWeight={400} mb="30px">
        {t('confirmationLink')}
        <Text
          color="grey.250"
          display="inline"
          ml="4px"
          textDecoration="underline">
          {username}
        </Text>
      </Text>
      <Text color="grey.250" fontWeight={400} mb="15px">
        {t('enterCode')}
      </Text>
      <Verify>
        <ReactInputVerificationCode
          length={6}
          placeholder=""
          onChange={(data) => setCode(data)}
        />
        <Button
          isDisabled={isLoading || code?.length < 6}
          isLoading={isLoading}
          mt="30px"
          variant="primary"
          onClick={handleSubmit}>
          {t('Verify')}
        </Button>
      </Verify>

      <HStack
        alignItems="center"
        justifyContent="flex-end"
        mt="15px"
        spacing="4px">
        <Text color="mainGray.100" fontSize="12px" fontWeight={400}>
          {t('didntGetEmail')}
        </Text>
        <Button
          fontSize="12px"
          textDecoration="none"
          variant="link"
          onClick={handleClick}>
          {t('sendAgain')}
        </Button>
      </HStack>
    </Box>
  );
};
