import { Accordion } from '@chakra-ui/react';
import React, { FC } from 'react';

import { OfferFragment } from '@app/api/schemas/offer.amazon.generated';

import { OfferPriceAccordionItem } from './OfferPriceAccordionItem';

interface OfferPricesAccordionProps {
  offer: OfferFragment;
  isPreview?: boolean;
}

export const OfferPricesAccordion: FC<OfferPricesAccordionProps> = ({
  offer,
  isPreview = false,
}) => {
  return (
    <Accordion allowToggle>
      {offer?.offerAddition?.map((item, index) => (
        <OfferPriceAccordionItem
          key={`prices-${index}`}
          estimateType={offer?.priceEstimateType}
          hasCommision={offer?.isCommissionIncluded}
          isPreview={isPreview}
          item={item}
          type={offer?.type}
        />
      ))}
    </Accordion>
  );
};
