import {
  HStack,
  Box,
  Flex,
  IconButton,
  useDisclosure,
  Image,
  Text,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { Link, useSearchParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Help } from '@app/icons/new-help-icon.svg';
import { NotificationWidget } from '@app/components/NotificationWidget';

import { HelpModal } from './HelpModal';

export const HelpWidget = () => {
  const [searchParams] = useSearchParams(location.search);
  const modal = searchParams.get('modal');
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { t } = useTranslation('navbar');

  useEffect(() => {
    if (modal) {
      onOpen();
    }
  }, [modal]);

  return (
    <HStack
      alignContent="center"
      cursor="pointer"
      justifyContent={'space-between'}
      p={{ base: '8px', md: '0' }}
      position={{ base: 'relative', md: 'absolute' }}
      right={{ base: 0, md: 5 }}
      top={{ base: 0, md: 7 }}
      w={{ base: 'full', md: 'auto' }}
      zIndex="20">
      <Box display={{ sm: 'block', md: 'none' }}>
        <HStack alignItems="center" as={Link} flex={1} to="/">
          <Image maxH="50px" src="/logo.png" />
          <Text fontSize={'16px'} fontWeight={600}>
            {t('logoTitle')}
          </Text>
        </HStack>
      </Box>
      <Flex gap="10px">
        <NotificationWidget />
        <Box onClick={onOpen}>
          <IconButton
            _hover={{
              backgroundColor: 'transparent',
            }}
            aria-label="Notifications"
            backgroundColor="gray.100"
            icon={<Help height={28} width={28} />}
            variant="icon"
          />
        </Box>
      </Flex>
      {isOpen && <HelpModal isOpen={isOpen} onClose={onClose} />}
    </HStack>
  );
};
