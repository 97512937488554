import { Box, Heading, Text } from '@chakra-ui/react';
import React, { KeyboardEvent } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { confirmResetPassword } from 'aws-amplify/auth';

import { FormField } from '@app/components/formInputs/FormField';
import { useSuccessNotification } from '@app/hooks/useSuccessNotification';
import { useErrorNotification } from '@app/hooks/useErrorNotification';
import { InputPassword } from '@app/components/FormElements/InputPassword';

import { Submit } from '../Login/components/LoginForm/Submit';

import { useNewPasswordSchema } from './useNewPasswordSchema';
import { NewPasswordFormProps, newPaswordInitialValues } from './initialValues';

export const NewPassword = () => {
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();
  const username = state?.username;
  const { t } = useTranslation('forms');
  const errorToast = useErrorNotification();
  const successToast = useSuccessNotification();

  const resolver = useNewPasswordSchema();
  const form = useForm({
    defaultValues: newPaswordInitialValues,
    resolver,
  });

  const onSubmit = async (values: NewPasswordFormProps) => {
    try {
      await confirmResetPassword({
        username,
        confirmationCode: values.code,
        newPassword: values.password,
      });
      successToast('Success');
      navigate('/');
    } catch (error) {
      errorToast(error);
    }
  };

  const onkeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      void form.handleSubmit(onSubmit)();
    }
  };

  return (
    <Box>
      <Heading color="mainGray.500" fontSize="34px" fontWeight={600} mb="15px">
        {t('setNewPassword')}
      </Heading>
      <Text color="grey.250" fontWeight={400} mb="30px">
        {t('confirmationLink')}
        <Text
          color="grey.250"
          display="inline"
          ml="4px"
          textDecoration="underline">
          {username}
        </Text>
      </Text>
      <FormProvider {...form}>
        <FormField
          isRequired
          fontSize="sm"
          fontWeight="500"
          mb="25px"
          ms={{ base: '0px', md: '0px' }}
          name="code"
          placeholder={t('code')}
          size="lg"
          variant="auth"
          onKeyDown={onkeyDown}
        />

        <FormField
          Component={InputPassword}
          mb="25px"
          name="password"
          placeholder={t('password')}
          variant="auth"
          onKeyDown={onkeyDown}
        />

        <Box mt="15px">
          <Submit title={t('updatePassword')} onSubmit={onSubmit} />
        </Box>
      </FormProvider>
    </Box>
  );
};
