import { useLocation, useNavigate } from 'react-router';

import { MessageReference } from '@app/api/gql/generated-types';
import { MessageFragment } from '@app/api/schemas/message.mongo.generated';
import {
  ContextMenuState,
  useContextMenu,
} from '@app/views/Chat/hooks/useContextMenu';

export enum ContextMenuActionsTypes {
  SET_MENU_OPEN = 'SET_MENU_OPEN',
  SET_SHOW_REPLY = 'SET_SHOW_REPLY',
  SET_SHOW_REFERENCE = 'SET_SHOW_REFERENCE',
  SET_MESSAGE = 'SET_MESSAGE',
  SET_SPAM = 'SET_SPAM',
  SET_DISABLE_REPORT = 'SET_DISABLE_REPORT',
  SET_POINTS = 'SET_POINTS',
  RESET_STATE = 'RESET_STATE',
}

type ContextMenuReducerPayloads = Partial<ContextMenuState>;

export type ContextMenuAction = {
  type: ContextMenuActionsTypes;
  payload: ContextMenuReducerPayloads | null;
};

export const useContextMenuActions = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { dispatch, state } = useContextMenu();

  const setOpenMenu = ({
    isOpen,
    replyMessage,
    points,
  }: {
    isOpen: boolean;
    replyMessage?: MessageFragment;
    points?: { x: number; y: number };
  }) =>
    dispatch({
      type: ContextMenuActionsTypes.SET_MENU_OPEN,
      payload: {
        isOpen,
        replyMessage,
        points,
      },
    });

  const setShowReference = (
    showReference: boolean,
    reference?: MessageReference,
  ) => {
    dispatch({
      type: ContextMenuActionsTypes.SET_SHOW_REFERENCE,
      payload: {
        showReference,
        reference,
      },
    });
    if (!showReference) {
      navigate(location.pathname, { replace: true });
    }
  };

  const setMessage = (message?: MessageFragment) =>
    dispatch({
      type: ContextMenuActionsTypes.SET_SHOW_REPLY,
      payload: {
        selectedMessage: message ?? state?.replyMessage,
      },
    });

  const setSpam = (isSpam: boolean) =>
    dispatch({
      type: ContextMenuActionsTypes.SET_SPAM,
      payload: {
        isSpam,
      },
    });

  const setDisableReport = (disableReport: boolean) =>
    dispatch({
      type: ContextMenuActionsTypes.SET_SHOW_REPLY,
      payload: {
        disableReport,
      },
    });

  const setInitState = () => {
    dispatch({
      type: ContextMenuActionsTypes.RESET_STATE,
      payload: null,
    });
  };

  return {
    setOpenMenu,
    setDisableReport,
    setSpam,
    setMessage,
    setInitState,
    setShowReference,
  };
};
