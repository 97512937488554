import {
  Box,
  Grid,
  HStack,
  Icon,
  IconButton,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactSortable } from 'react-sortablejs';
import { DropzoneOptions, FileRejection } from 'react-dropzone';

import { ImageData } from '@app/api/gql/generated-types-amazon';
import { DropFileIcon } from '@app/components/CustomDropzone/DropFileIcon';
import Popup from '@app/components/Popup';
import { SearchImagesModal } from '@app/components/SearchImages';
import { ReactComponent as CloudUploadIcon } from '@app/icons/upload-icon.svg';
import { ReactComponent as Remove } from '@app/icons/remove-icon.svg';
import { FileDropzone } from '@app/components/CustomDropzone/FileDropzone';
import { useErrorNotification } from '@app/hooks/useErrorNotification';

interface PhotoUploadProps {
  setFiles: (f: File[]) => void;
  files?: File[] | ImageData[];
  errors?: string;
}

export const PhotoUpload: FC<PhotoUploadProps> = ({
  setFiles,
  files,
  errors,
}) => {
  const { t } = useTranslation('forms');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const errorNotification = useErrorNotification();

  const gridCount = useMemo(
    () => (files?.length > 5 ? 5 : files?.length),
    [files?.length],
  );

  const onAddFile = (photo) => {
    setFiles([...(files ?? []), ...(photo ?? [])]);
    onClose();
  };

  const onRemove = (index: number) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  const onLocalRemove = (item: number, e) => {
    e.preventDefault();
    e.stopPropagation();
    onRemove(item);
  };
  const handleClickItem = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onDrop = useCallback(
    (uploaded: File[], rejectedFiles: FileRejection[]) => {
      if (rejectedFiles.length > 0) {
        const rejectedFilesString = rejectedFiles
          ?.map((file) => file.file.name)
          ?.join();
        errorNotification({
          message: `${rejectedFilesString}. ${t('uploadFile')}`,
        });
      }

      const oldFiles = files?.length ? files : [];

      setFiles([...oldFiles, ...uploaded]);
    },
    [files],
  );

  const dropzoneOptions: DropzoneOptions = {
    onDrop,
  };

  return (
    <>
      <VStack
        alignItems="center"
        background="gray.100"
        border="1px solid"
        borderColor={errors ? 'red.600' : 'gray.100'}
        borderRadius={12}
        cursor="pointer"
        direction="column"
        overflow="hidden"
        p={3}
        spacing={1}
        onClick={onOpen}>
        {!!files?.length ? (
          <Grid
            as={ReactSortable}
            columnGap="10px"
            gridTemplateColumns={`repeat(${gridCount}, 1fr)`}
            list={files}
            my="10px"
            rowGap="10px"
            setList={setFiles}>
            {files.map((file, i) => (
              <Box key={i} position="relative" onClick={handleClickItem}>
                <DropFileIcon file={file} />
                <IconButton
                  aria-label="Remove"
                  color="secondaryGray.600"
                  position="absolute"
                  right={1}
                  size="xs"
                  top={1}
                  onClick={(e) => onLocalRemove(i, e)}>
                  <HStack justifyContent="center">
                    <Remove />
                  </HStack>
                </IconButton>
              </Box>
            ))}
          </Grid>
        ) : (
          <Icon as={CloudUploadIcon} h="44px" w="44px" />
        )}
        <Text
          align="center"
          color="brand.900"
          cursor="pointer"
          fontSize="md"
          fontWeight="700"
          mx="auto">
          {t('chooseFiles')}
        </Text>
        <Text align="center" color="gray.200" fontSize={12} fontWeight="500">
          {t('or')}
        </Text>
        <HStack justifyContent="center">
          <FileDropzone
            content={t('dropTitle', { ns: 'addOffer' })}
            dropzoneOptions={dropzoneOptions}
            h={7}
            mb={1}
            mt={1}
            variant="dark"
          />
        </HStack>

        <Text align="center" color="gray.200" fontSize={12} fontWeight="500">
          {t('dropSubTitle', { ns: 'addOffer' })}
        </Text>
      </VStack>
      {errors && (
        <Box
          color="red.600"
          fontSize="12px"
          fontWeight={400}
          ml={1}
          mt={1}
          w="auto">
          {t('dropZoneInvalid', { ns: 'validations' })}
        </Box>
      )}
      {isOpen && (
        <Popup
          hideCloseIcon
          closeOnEsc={true}
          isOpen={isOpen}
          maxW={{ '2xl': '780px', base: '500px' }}
          scrollBehavior="inside"
          onClose={onClose}>
          <SearchImagesModal onAddFile={onAddFile} onClose={onClose} />
        </Popup>
      )}
    </>
  );
};
