import React, { useCallback, useEffect } from 'react';
import { useSearchParams, useLocation, useNavigate } from 'react-router';

import { useAuth } from '@app/hooks/useAuth';

export const ConfirmEmail = () => {
  const { confirmEmail } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams(location.search);
  const token = searchParams.get('token');
  const tokenId = searchParams.get('tokenId');

  const confirm = useCallback(async () => {
    await confirmEmail({ token, tokenId });
    navigate('/');
  }, [confirmEmail, navigate, token, tokenId]);

  useEffect(() => {
    if (token && tokenId) {
      void confirm();
      return;
    }
    navigate('/');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};
