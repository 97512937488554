import { Button, HStack, useDisclosure } from '@chakra-ui/react';
import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { OrderChangeStatusModal } from '@app/components/OrderChangeStatusModal';
import { useSuccessNotification } from '@app/hooks/useSuccessNotification';
import { useAddOfferModalContext } from '@app/providers/AddOfferModalProvider';
import {
  OrderDocument,
  useCancelOrderMutation,
  useConfirmOrderPaymentMutation,
} from '@app/api/schemas/order.amazon.generated';
import { useErrorNotification } from '@app/hooks/useErrorNotification';
interface OrderStatusButtonsProps {
  id?: string;
  offerId?: string;
  canUpdate?: boolean;
  canSubmitPayment?: boolean;
}

const OrderStatusButtons: FC<OrderStatusButtonsProps> = ({
  id,
  offerId,
  canUpdate = true,
  canSubmitPayment = false,
}) => {
  const { t } = useTranslation(['forms', 'notifications']);
  const { handleOpen } = useAddOfferModalContext();
  const notification = useSuccessNotification();
  const errorNotification = useErrorNotification();
  const reasonDisclosure = useDisclosure();
  const [submitPaymentMutation] = useConfirmOrderPaymentMutation({
    context: {
      clientName: 'amazon',
    },
    refetchQueries: [
      {
        query: OrderDocument,
        variables: {
          id,
        },
        context: {
          clientName: 'amazon',
        },
      },
    ],
  });
  const [mutate] = useCancelOrderMutation({
    context: {
      clientName: 'amazon',
    },
    refetchQueries: [
      {
        query: OrderDocument,
        variables: {
          id,
        },
        context: {
          clientName: 'amazon',
        },
      },
    ],
  });
  const [reason, setReason] = useState();
  const changeOrderStatus = async () => {
    try {
      await mutate({
        variables: {
          id,
          reason,
        },
      });
      reasonDisclosure.onClose();
    } catch (error) {
      console.log(error);
    } finally {
      notification(t('booking.order.cancelled', { ns: 'notifications' }));
      setReason(null);
    }
  };

  const handleEditOffer = useCallback(async () => {
    try {
      await handleOpen({ offerId, orderId: id });
    } catch (error) {}
  }, [id, offerId]);

  const handleSubmitPayment = useCallback(async () => {
    try {
      await submitPaymentMutation({ variables: { id } });
      notification(t('updateOrderPrice', { ns: 'addOffer' }));
    } catch (error) {
      errorNotification(error);
    }
  }, [id]);

  return (
    <>
      <HStack alignItems="center" justifyContent="center" py={2} spacing={4}>
        <Button
          flex={2}
          height={10}
          size="sm"
          variant="outlineGray"
          onClick={reasonDisclosure.onOpen}>
          {t('cancelOrder')}
        </Button>
        {canUpdate && (
          <Button
            flex={3}
            height={10}
            size="sm"
            variant="primary"
            onClick={handleEditOffer}>
            {t('updateDetails')}
          </Button>
        )}
        {canSubmitPayment && (
          <Button
            flex={3}
            height={10}
            size="sm"
            variant="primary"
            onClick={handleSubmitPayment}>
            {t('submitPayment')}
          </Button>
        )}
      </HStack>
      {reasonDisclosure.isOpen && (
        <OrderChangeStatusModal
          {...reasonDisclosure}
          title={`${t('cancelOrderTitle')}?`}
          value={reason}
          onChange={setReason}
          onSubmit={changeOrderStatus}
        />
      )}
    </>
  );
};

export default OrderStatusButtons;
