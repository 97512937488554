import {
  Box,
  Button,
  Flex,
  HStack,
  InputGroup,
  InputRightElement,
  Text,
  Textarea,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Account,
  SendMessageInputAttachmentType,
} from '@app/api/gql/generated-types';
import { useGetChatLazyQuery } from '@app/api/schemas/chat.mongo.generated';
import { useSendMessageMutation } from '@app/api/schemas/message.mongo.generated';
import { db } from '@app/db';
import { useUploadFiles } from '@app/hooks/useUploadFiles';
import { useChatWidgetContext } from '@app/providers/ChatWidgetProvider';
import { getOS } from '@app/utils/getOS';
import { ReactComponent as SendIcon } from 'icons/send-message-icon.svg';

import { useContextMenuActions } from '../../../hooks/contextMenuActions';
import { useContextMenu } from '../../../hooks/useContextMenu';
import { MessageReferencePlate } from '../MessageReferencePlate';
import { MessageReplay } from '../MessageReplay';
import { UploadPhoto } from '../UploadPhoto';

import { ActionButtons } from './ActionButtons';

interface FooterProps {
  id: string;
  participants: Account[];
  otherAccountId: string;
  isWidget?: boolean;
}

export const Footer: FC<FooterProps> = ({
  id,
  participants,
  otherAccountId,
  isWidget = false,
}) => {
  const { setActiveChat } = useChatWidgetContext();
  const { t } = useTranslation(['forms', 'modalsAndMenus']);
  const [sendMessage, { loading }] = useSendMessageMutation();

  const [getNewChat] = useGetChatLazyQuery();

  const [row, setRows] = useState<number>(1);
  const { uploadToAccount, isLoading } = useUploadFiles();
  const { setMessage, setDisableReport, setShowReference } =
    useContextMenuActions();

  const { state } = useContextMenu();

  const confirmUploadImg = useDisclosure();
  const [inputMessage, setInputMessage] = useState<string>('');
  const [imageObj, setImageObj] = useState<File>();
  const inputText = useColorModeValue('gray.700', 'gray.100');
  const blockBg = useColorModeValue('secondaryGray.300', 'navy.700');

  const isBig = useMemo(
    () => !inputMessage || inputMessage?.trim().length <= 0,
    [inputMessage],
  );
  const isDisabled = useMemo(
    () => isBig || loading || isLoading,
    [isBig, isLoading, loading],
  );

  const onEmojiChange = useCallback((val: string) => {
    setInputMessage((old) => old + val);
  }, []);

  const handleSendMessage = async (text?: string) => {
    if (isDisabled && !text && !imageObj) {
      return;
    }

    try {
      const content = text ?? inputMessage?.trim();
      let imagesArr;

      if (!!imageObj) {
        const photo = await uploadToAccount(imageObj);
        imagesArr = [
          {
            filename: imageObj?.name,
            type: SendMessageInputAttachmentType.IMAGE,
            url: photo?.key as string,
          },
        ];
      }

      await sendMessage({
        variables: {
          input: {
            ...(!!id && { chatId: id }),
            content: content ?? '',
            ...(!!imagesArr && { attachments: imagesArr }),
            ...(state?.selectedMessage?._id && {
              replyTo: state?.selectedMessage?._id,
            }),
            ...(!!otherAccountId && { otherAccountId }),
            ...(!!state?.reference && { reference: state.reference }),
          },
        },
        onCompleted: async (data) => {
          confirmUploadImg.onClose();
          setInputMessage('');
          setMessage(null);
          setShowReference(false);
          setDisableReport(false);
          setImageObj(null);
          if (!id) {
            const {
              data: { getChat },
            } = await getNewChat({
              variables: {
                input: {
                  chatId: data?.sendMessage?.chatId,
                },
              },
            });
            db.chats.add(getChat);
            setActiveChat(getChat);
          }
        },
        updateQueries: {
          FindMessages: (previousData, { mutationResult }) => {
            const message = mutationResult.data.sendMessage;
            return {
              ...previousData,
              findMessages: {
                ...previousData.findMessages,
                items: [message, ...previousData.findMessages.items],
              },
            };
          },
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangeImage = (file: File) => {
    setImageObj(file);
    if (!!file) {
      confirmUploadImg.onOpen();
    }
  };

  const onSubmitSendPhoto = async (text: string) => {
    await handleSendMessage(text);
    confirmUploadImg.onClose();
  };

  useEffect(() => {
    const rowlen = inputMessage?.split?.('\n');

    if (rowlen?.length >= 1 && rowlen?.length <= 4) {
      setRows(rowlen?.length);
    }
  }, [inputMessage]);

  return (
    <Flex
      bg={isWidget ? 'grey.100' : 'trasparent'}
      flexDirection="column"
      m="auto"
      maxW="680px"
      padding={isWidget ? '10px 10px' : '10px 0'}
      position="relative"
      w="100%">
      {!!state?.selectedMessage && (
        <MessageReplay isWidget={isWidget} participants={participants} />
      )}
      {!!state?.showReference && <MessageReferencePlate isWidget={isWidget} />}
      <HStack alignItems="flex-end" my={isWidget ? 1 : 2}>
        <ActionButtons
          chatId={id}
          isShowSendOffer={!!id}
          isSmall={!isBig}
          onChangeImage={handleChangeImage}
          onEmojiAdd={onEmojiChange}
        />
        <InputGroup
          bg="grey.100"
          borderRadius={16}
          borderTopLeftRadius={!!state?.selectedMessage ? 0 : 16}
          borderTopRightRadius={!!state?.selectedMessage ? 0 : 16}
          justifyContent="flex-end"
          w={{ base: '100%' }}>
          <InputRightElement
            alignItems="flex-end"
            display={{ base: 'none', md: isWidget ? 'none' : 'flex' }}
            h="full"
            w="120px">
            <Button
              _disabled={{ opacity: 0.4 }}
              flexDirection="column"
              h="42px"
              isDisabled={isDisabled}
              isLoading={loading}
              mb={0}
              minW="full"
              mr="10px"
              padding="0px"
              transition="all 0.15s"
              variant="primary"
              onClick={() => handleSendMessage(null)}>
              <Box mb={-1}>
                <Text color="white" fontSize="16px" lineHeight="20px">
                  {t('send')}
                </Text>
                <Text color="white" fontSize="10px" opacity="0.6">
                  {getOS() === 'macos' ? 'Cmd + return' : 'Ctrl + Enter'}
                </Text>
              </Box>
            </Button>
          </InputRightElement>
          <Textarea
            autoFocus
            bg={isWidget ? 'white.0' : blockBg}
            borderRadius={'12px'}
            color={inputText}
            fontSize={isWidget ? 14 : 16}
            fontWeight="500"
            lineHeight="24px"
            overflow="hidden"
            overflowY="auto"
            pl="15px"
            placeholder={t('writeMessage')}
            pr={
              isWidget
                ? {
                    base: '0px',
                    lg: '15px !important',
                  }
                : {
                    base: '0px',
                    lg: '145px !important',
                  }
            }
            resize="none"
            rows={row}
            transition="all 0.1s"
            value={inputMessage}
            variant="search"
            onChange={(e) => setInputMessage(e.target.value)}
            onKeyDown={(e) => {
              if (
                e.key === 'Enter' &&
                (getOS() === 'macos' ? e.metaKey : e.ctrlKey)
              ) {
                void handleSendMessage();
              }
            }}
          />
        </InputGroup>
        <Button
          _disabled={{ opacity: 0.4 }}
          _hover={{
            backgroundColor: 'mainGray.200',
          }}
          display={{ base: 'block', md: isWidget ? 'block' : 'none' }}
          flexDirection="column"
          h={8}
          isDisabled={isDisabled}
          isLoading={loading}
          mb={1}
          minW={8}
          p={0}
          transition="all 0.15s"
          variant="icon"
          onClick={() => handleSendMessage(null)}>
          <SendIcon />
        </Button>
      </HStack>
      {confirmUploadImg.isOpen && (
        <UploadPhoto
          {...confirmUploadImg}
          image={imageObj}
          isLoading={loading || isLoading}
          onSubmit={onSubmitSendPhoto}
        />
      )}
    </Flex>
  );
};
