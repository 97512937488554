import omit from 'lodash/omit';
import { useCallback, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';

import { useUploadFiles } from '@app/hooks/useUploadFiles';
import { removeNil } from '@app/utils/removeNil';
import { useInitialValues } from '@app/views/AddOffer/hooks/useInitialValues';
import { useValidationSchema } from '@app/views/AddOffer/schema/create-offer';
import { useSuccessNotification } from '@app/hooks/useSuccessNotification';
import { useErrorNotification } from '@app/hooks/useErrorNotification';
import { amplitude } from '@app/amplitude';
import {
  OfferFragment,
  useCreateOfferMutation,
  useUpdateOfferMutation,
} from '@app/api/schemas/offer.amazon.generated';
import {
  CreateOfferInput,
  OfferPriceEstimateType,
  OfferType,
  UpdateOfferInput,
} from '@app/api/gql/generated-types-amazon';

export const useOfferFormSubmit = ({ hasRedirect = true, values } = {}) => {
  const { initialValues: defaultValues, offerValues } = useInitialValues();
  const isDraftRef = useRef(false);
  const isPriceEditRef = useRef(false);
  const { t } = useTranslation('addOffer');
  const { draftSchema, offerSchema, editPriceSchema } = useValidationSchema();

  const form = useForm<CreateOfferInput | OfferFragment>({
    defaultValues,
    resolver: (...args) => {
      // if (isDraftRef.current) {
      //   return draftSchema(...args);
      // } // TODO fix on new BE
      if (isPriceEditRef.current) {
        return editPriceSchema(...args);
      }
      return offerSchema(...args);
    },
    mode: 'all',
    values: { ...offerValues, ...values },
    enableReinitialize: true,
  });
  const [isLoading, setIsLoading] = useState(false);
  const { uploadMultiple } = useUploadFiles();
  const [insert, { loading: isLoadingCreate }] = useCreateOfferMutation({
    context: {
      clientName: 'amazon',
    },
  });
  const [update, { loading: isLoadingUpdate }] = useUpdateOfferMutation({
    context: {
      clientName: 'amazon',
    },
  });

  const navigate = useNavigate();
  const successToast = useSuccessNotification();
  const errorToast = useErrorNotification();
  const createOffer = useCallback(async (values: CreateOfferInput) => {
    setIsLoading(true);
    let response = '';
    try {
      const photo = await uploadMultiple(values?.photo);

      const data = omit(values, [
        'photo',
        'tags',
        'children',
        'owner',
        'updatedAt',
        'createdAt',
        'likeCount',
      ]);

      const offerAddition = data?.offerAddition?.map((item) => ({
        ...omit(item, ['__typename', 'children', 'isSaved']),
        ...(item.children && { children: Number(item.children) }),
        ...(item.adults && { adults: Number(item.adults) }),
        days: Number(item?.days),
        price: {
          value: !isNil(item.price.value) ? item.price.value * 100 : 0,
          currency: item.price.currency?._id,
        },
      }));

      const { startDate, days, price, adults, children } =
        data?.offerAddition?.[0];

      const payload = {
        ...removeNil(data),
        startDate,
        days: Number(days),
        activities: data.activities?.map((activity) => activity._id),
        price: {
          value: !isNil(price.value) ? price.value * 100 : 0,
          currency: price.currency?._id,
        },
        photo,
        adults: Number(adults),
        ...(children && { children: Number(children) }),
        ...(values.tags?.length && {
          tags: values.tags.map((tag) => tag._id),
        }),
        ...(values.location && {
          location: {
            ...omit(values.location, ['__typename']),
            coordinates: omit(values.location.coordinates, ['__typename']),
          },
        }),
        offerAddition,
      };

      if (data?._id) {
        const clippedPayload = omit(payload, ['__typename', '_id']);
        const { data: { updateOffer } = {} } = await update({
          variables: {
            id: data?._id,
            input: clippedPayload,
          },
        });
        successToast(t('updateOfferSuccess'));

        amplitude.logEvent('[offer] Create', { platform: 'web' });
        response = updateOffer?._id;
      } else {
        const { data: { createOffer: insertOffer } = {} } = await insert({
          variables: {
            input: payload,
          },
        });
        amplitude.logEvent('[offer] Update', { platform: 'web' });
        response = insertOffer?._id;
        successToast(t('addOfferSuccess'));
      }
      if (hasRedirect) {
        navigate('/offers');
      }

      return response;
    } catch (error) {
      errorToast({ message: t('addOfferFail') });
    } finally {
      setIsLoading(false);
    }
  }, []);

  const updateOfferPrice = useCallback(
    async (values: Partial<UpdateOfferInput>) => {
      setIsLoading(true);

      let response = '';
      try {
        const payload = {
          startDate: new Date(values?.startDate),
          days: Number(values?.days),
          price: {
            value: !!values?.price.value ? Number(values.price.value) * 100 : 0,
            currency: values?.price.currency?._id,
          },
          priceEstimateType: OfferPriceEstimateType.FINAL,
          adults: Number(values?.adults),
          type: OfferType.TOUR,
          children: !isEmpty(values.children) ? Number(values.children) : 0,
          isCommissionIncluded: values?.isCommissionIncluded,
        };

        if (values?._id) {
          const { data: { updateOffer } = {} } = await update({
            variables: {
              id: values?._id,
              input: payload,
            },
          });

          response = updateOffer?._id;
        }

        successToast(t('updateOrderPrice'));
        return response;
      } catch (error) {
        errorToast({ message: t('addOfferFail') });
      } finally {
        setIsLoading(false);
      }
    },
    [],
  );

  return {
    onSubmit: createOffer,
    onUpdatePrice: updateOfferPrice,
    isLoading: isLoading || isLoadingCreate || isLoadingUpdate,
    form,
    defaultValues,
    isDraftRef,
    isPriceEditRef,
  };
};
