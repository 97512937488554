import React, { FC, PropsWithChildren } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import {
  Image,
  ListItem,
  Link,
  OrderedList,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router';

const options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      return (
        <Image maxW="full" my={4} src={node?.data?.target?.fields?.file?.url} />
      );
    },
    [BLOCKS.HEADING_1]: (_, text) => {
      return (
        <Text fontSize={{ bsae: 18, md: 32 }} fontWeight={600} my={4}>
          {text}
        </Text>
      );
    },
    [BLOCKS.HEADING_2]: (_, text) => {
      return (
        <Text fontSize={{ bsae: 14, md: 28 }} fontWeight={600} my={4}>
          {text}
        </Text>
      );
    },
    [BLOCKS.HEADING_3]: (_, text) => {
      return (
        <Text fontSize={{ base: 12, md: 24 }} fontWeight={500} my={4}>
          {text}
        </Text>
      );
    },
    [BLOCKS.UL_LIST]: (_, text) => {
      return (
        <UnorderedList ml={6} my={3}>
          {text}
        </UnorderedList>
      );
    },
    [BLOCKS.OL_LIST]: (_, text) => {
      return (
        <OrderedList ml={6} my={3}>
          {text}
        </OrderedList>
      );
    },
    [BLOCKS.LIST_ITEM]: (_, text) => {
      return <ListItem my={2}>{text}</ListItem>;
    },
    [INLINES.HYPERLINK]: ({ data }, children) => {
      return (
        <Link color="primary.green" href={data?.uri} textDecoration="underline">
          {children}
        </Link>
      );
    },
    [INLINES.ENTRY_HYPERLINK]: ({ data }, children) => {
      if (data?.target?.sys?.contentType?.sys?.id === 'articlesFaqAgentsApp') {
        return (
          <Link
            _hover={{
              opacity: 0.8,
            }}
            as={RouterLink}
            color="mainGray.100"
            cursor="pointer"
            preventScrollReset={true}
            to={`?categoryId=${data?.target?.fields?.category?.sys?.id}&node=${data?.target?.sys?.id}`}>
            {children}
          </Link>
        );
      }
      return children;
    },
  },
};

export const TextRenderer: FC<PropsWithChildren> = ({ children }) => {
  return <>{documentToReactComponents(children, options)}</>;
};
