import * as Types from '@/gql/__generated__/types';

import { gql } from '@apollo/client';
export type CharacteristicFragment = { __typename?: 'TravelerCharacteristic', _id: string, parent: { __typename?: 'TravelerCharacteristic', _id: string } | null, details: Array<{ __typename?: 'TravelerCharacteristicsDetail', lang: string | null, title: string | null }> | null };

export const CharacteristicFragmentDoc = gql`
    fragment Characteristic on TravelerCharacteristic {
  _id
  parent {
    _id
  }
  details {
    lang
    title
  }
}
    `;