import React, { FC, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Text,
  Textarea,
  UseDisclosureProps,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import Popup from '../Popup';

interface OrderChangeStatusModalProps extends UseDisclosureProps {
  onSubmit: () => void;
  title?: string;
  width?: string;
  confirmBtnText?: string;
  value: string;
  onChange: (val: string) => void;
}

export const OrderChangeStatusModal: FC<OrderChangeStatusModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  title,
  width = '500px',
  confirmBtnText,
  value,
  onChange,
}) => {
  const { t } = useTranslation(['modalsAndMenus']);
  const popupTitle = title || t('confirmation');
  const popupConfirmBtnText = confirmBtnText || t('confirmBtnText');
  const [agree, setAgree] = useState<boolean>(false);
  return (
    <Popup
      closeOnOverlayClick
      hideCloseIcon={false}
      isOpen={isOpen}
      maxW={width}
      title={popupTitle}
      onClose={onClose}>
      <Box p="30px 60px 30px">
        <Text mb={4}>
          {t('bookingCancelDescriptionRefund', { ns: 'forms' })}
        </Text>
        <Text mb={6}>
          {t('bookingCancelDescriptionCommission', { ns: 'forms' })}
        </Text>
        <Textarea
          bg="grey.100"
          borderRadius="16px"
          color="brand.900"
          colorScheme="green"
          placeholder={t('reason')}
          value={value}
          variant="main"
          onChange={(val) => onChange(val.target.value)}
        />
        <Checkbox
          mt={10}
          value={agree}
          variant="primary"
          onChange={() => setAgree((val) => !val)}>
          {t('bookingCancelConfirmText', { ns: 'forms' })}
        </Checkbox>
        <Flex direction="column" mt={4}>
          <Button
            isDisabled={!value || !agree}
            variant="primary"
            onClick={onSubmit}>
            {popupConfirmBtnText}
          </Button>
          <Button variant="cancel" onClick={onClose}>
            {t('cancel')}
          </Button>
        </Flex>
      </Box>
    </Popup>
  );
};
