import { HStack, Skeleton, VStack } from '@chakra-ui/react';
import React, { FC } from 'react';

interface SkeletonProps {
  isWidget?: boolean;
}

export const SkeletonItem: FC<SkeletonProps> = ({ isWidget = false }) => {
  return (
    <HStack spacing={isWidget ? 2 : 3}>
      <Skeleton borderRadius={8} height="50px" width="50px" />
      <VStack alignItems="flex-start" flex={1} spacing={2}>
        <Skeleton borderRadius={2} height="14px" width="full" />
        <Skeleton borderRadius={2} height="14px" width="75%" />
      </VStack>
    </HStack>
  );
};
