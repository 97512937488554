import { Box, List, ListItem, Text, VStack } from '@chakra-ui/react';
import capitalize from 'lodash/capitalize';
import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { addDays } from 'date-fns';
import { useCopyToClipboard } from 'usehooks-ts';

import { DateFormatter } from '@app/components/DateFormatter';
import { PriceField } from '@app/components/PriceField';
import { InformationItem } from '@app/views/Offers/components/Information/InformationItem';
import { FullRequestInfoDescription } from '@app/components/FullRequestInfoDescription';
import { OrderFullFragment } from '@app/api/schemas/order.amazon.generated';
import { useSuccessNotification } from '@app/hooks/useSuccessNotification';

interface OrderInformationProps {
  order?: Partial<OrderFullFragment>;
}

export const OrderInformation: FC<OrderInformationProps> = ({ order }) => {
  const { t } = useTranslation(['tables']);
  const [, copy] = useCopyToClipboard();
  const successNotification = useSuccessNotification();
  const endDate = useMemo(
    () =>
      !!order?.offer?.startDate && !!order?.offer?.days
        ? addDays(new Date(order.offer.startDate), Number(order.offer.days))
        : null,
    [order?.offer?.days, order?.offer?.startDate],
  );

  const handleCopy = useCallback(async (val: string) => {
    await copy(val);
    successNotification(`${val} ${t('textCopied', { ns: 'notifications' })}`);
  }, []);

  return (
    <>
      {!!order?.orderNumber && (
        <InformationItem
          title={t('orderNumber')}
          onClick={() => handleCopy(order.orderNumber.toString())}>
          <Text color="black" fontSize="14px" fontWeight={600}>
            #{order.orderNumber}
          </Text>
        </InformationItem>
      )}
      <InformationItem title={t('date')}>
        <Text color="black" fontSize="14px" fontWeight={600}>
          {!!order?.offer?.startDate && !!endDate && (
            <>
              <DateFormatter date={new Date(order.offer.startDate)} /> -{' '}
              <DateFormatter date={new Date(endDate)} />
            </>
          )}
        </Text>
      </InformationItem>
      <InformationItem title={t('orderDate')}>
        <Text color="black" fontSize="14px" fontWeight={600}>
          {!!order?.createdAt && (
            <DateFormatter date={new Date(order?.createdAt)} />
          )}
        </Text>
      </InformationItem>

      <InformationItem title={t('adults')}>
        <Text color="black" fontSize="14px" fontWeight={600}>
          {order?.offer?.adults}
        </Text>
      </InformationItem>
      {!!Number(order?.offer?.children) && (
        <InformationItem title={t('children')}>
          <Text color="black" fontSize="14px" fontWeight={600}>
            {order?.offer?.children}
          </Text>
        </InformationItem>
      )}
      <InformationItem title={t('price')}>
        <Text color="primary.green" fontSize="14px" fontWeight={600}>
          <PriceField
            value={
              !!order?.offer?.price?.value
                ? order?.offer?.price
                : { ...order?.offer?.price?.currency, value: 0 }
            }
          />
        </Text>
      </InformationItem>

      <InformationItem title={t('status')}>
        <Text color="black" fontSize="14px" fontWeight={600}>
          {capitalize(t(`orderStatus.${order?.status}`))}
        </Text>
      </InformationItem>

      <InformationItem title={t('paymentStatus')}>
        <Text color="black" fontSize="14px" fontWeight={600}>
          {capitalize(t(`paymentTransactionStatus.${order?.paymentStatus}`))}
        </Text>
      </InformationItem>

      {!!order?.additionalNotes && (
        <Box py={1.5}>
          <Text color="grey.300" fontSize="14px" fontWeight={400} mb={1}>
            {t('travelDetails')}
          </Text>
          <FullRequestInfoDescription description={order?.additionalNotes} />
        </Box>
      )}

      {!!order?.reasons?.length && (
        <Box mt={4} pt={1}>
          <Text color="grey.300" fontSize="14px" fontWeight={400} mb={1}>
            {t('cancelationReason')}
          </Text>
          <List mt={2}>
            {order?.reasons?.map((item, index) => (
              <ListItem key={index} py={1}>
                <VStack alignItems="flex-start" spacing={0} w="full">
                  <Text color="mainGray.100" fontSize={14}>
                    {item?.description}
                  </Text>
                  <Text alignSelf="flex-start" color="grey.250" fontSize={12}>
                    <DateFormatter date={new Date(item?.createdAt)} />
                  </Text>
                </VStack>
              </ListItem>
            ))}
          </List>
        </Box>
      )}
    </>
  );
};
