import { useState } from 'react';

import { FindRequestDocument } from '@app/api/schemas/request.mongo.generated';
import {
  OfferPreviewFragment,
  OfferPreviewFragmentDoc,
  useUpdateOfferMutation,
} from '@app/api/schemas/offer.amazon.generated';
import { Offer, OfferStatus } from '@app/api/gql/generated-types-amazon';

export const useOfferStatus = () => {
  const [offer, setOffer] = useState<Offer>();
  const [mutate] = useUpdateOfferMutation({
    refetchQueries: [FindRequestDocument],
    context: {
      clientName: 'amazon',
    },
  });

  const hideOffer = async () => {
    try {
      await mutate({
        variables: {
          id: offer?._id,
          input: {
            isPublic: false,
          },
        },
        update(cache, _result, { variables }) {
          cache.updateFragment<OfferPreviewFragment>(
            {
              id: `Offer:${variables.id}`,
              fragment: OfferPreviewFragmentDoc,
              fragmentName: 'OfferPreview',
            },
            (data) => {
              return {
                ...data,
                isPublic: false,
              };
            },
          );
        },
      });
    } catch (error) {
      console.error(error);
    }
  };
  const showOffer = async () => {
    try {
      await mutate({
        variables: {
          id: offer?._id,
          input: {
            isPublic: true,
          },
        },
        update(cache, _result, { variables }) {
          cache.updateFragment<OfferPreviewFragment>(
            {
              id: `Offer:${variables.id}`,
              fragment: OfferPreviewFragmentDoc,
              fragmentName: 'OfferPreview',
            },
            (data) => {
              return {
                ...data,
                isPublic: true,
              };
            },
          );
        },
      });
    } catch (error) {
      console.error(error);
    }
  };
  const changeStatus = async (status: OfferStatus) => {
    try {
      await mutate({
        variables: {
          id: offer?._id,
          input: {
            status,
          },
        },
        update(cache, _result, { variables }) {
          cache.updateFragment<OfferPreviewFragment>(
            {
              id: `Offer:${variables.id}`,
              fragment: OfferPreviewFragmentDoc,
              fragmentName: 'OfferPreview',
            },
            (data) => {
              return {
                ...data,
                status,
              };
            },
          );
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  return {
    offer,
    setOffer,
    hideOffer,
    showOffer,
    changeStatus,
  };
};
